import * as React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

/* @jsxImportSource @emotion/react */  import { css, jsx } from '@emotion/react'; /* eslint-disable-line */

import theme from 'app/theme';
import useComponentVisible from 'app/hooks/useComponentVisible';
import {userHasMultipleClients} from '../../util/hasAccess';

const sideCss = css`
	display: flex;
    align-items: center;
	justify-content: space-between;
	 
	ul {
		display: flex;
		align-items: center;
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			display: inline-block;
			position: relative;
			margin-left: 28px;
			line-height: 1;
			color: #fff;
			text-transform: uppercase;
			text-decoration: none;
			font-size: 16px;
			font-weight: 600;

			> a, > span > a {
				color: #fff;
				font-size: 16px;
				font-weight: 600;
				position: relative;
				text-transform: uppercase;
				text-decoration: none;

				::before {
					content: '';
					height: 2px;
					position: absolute;
					top: 100%;
					left: 50%;
					right: 50%;
					background: #fff;
					opacity: 0;
					transition: .3s cubic-bezier(.45,1.06,.58,1.31);
				}


				&:hover {
					&::before {
					  left: 0;
					  right: 0;
					  opacity: 1;
					}
				  }
			}
		}
	}
	
	.trigger {
		cursor: pointer;
	}
`;

const popupCss = css`
	position: absolute;
	margin-top: 30px;
	left: auto;
	right: 0;
	z-index: 1000;
	overflow: hidden;
	overflow-y: scroll;

	a {
		color: #000;
		font-size: 12px;
	}

	> div {
		
		width: 250px;
		position: relative;
		box-shadow: rgba(0,0,0,0.05) 0px 2px 6px, rgba(0,0,0,0.07) 0px 0px 0px 1px;
		animation-name: eMLfYp;
		animation-duration: 0.15s;
		animation-timing-function: ease-in;
		background: rgb(255,255,255);
		padding: 14px;
		border-radius: 2px;

		.title {
			font-family: Montserrat,sans-serif;
			font-size: 14px;
			font-weight: 600;
			line-height: 1.57;
			color: rgb(52,49,50);
		}
	}

`;

const ClientPickerMenu = ({ handleClick }) => {

	const clients = useSelector(state => state.CLIENT_LIST);

	const innerHeight = window.innerHeight;
	const headerHeight = 60;
	const extraPadding = 20;
	const maxHeight = innerHeight - headerHeight - extraPadding;
	const [searcher, setSearcher] = React.useState('');

	const shortenName = (name) => {

		if (name !== undefined && name !== null && name.substring(0, 9) === 'Providoor') {
			return `PR ${name.substring(9)}`;
		}

		return name;
	}

	let filteredItems = clients?.items?.filter(item => {
		const searcherLower = searcher.toLowerCase();

		if (searcher === '') {
			return true;
		}

		if (item.id === -1) {
			return true;
		}

		if (item.name.toLowerCase().search(searcherLower) > -1) {
			return true;
		}

		return false;
	});


	return <div css={popupCss} style={{maxHeight: `${maxHeight}px`}}>
		<div>
			<div className="title">Client Picker</div>

			{clients?.items?.length >= 10 && (<div>
				<input type="text" className="client-search" placeholder="Search..." value={searcher} onChange={(e) => {
					setSearcher(e.target.value);
				}} />
			</div>)}

			<div><em><Link to={`/cd/-1/d/dashboard`}>All</Link></em></div>

			{filteredItems.map(client => {
				return <div key={client.id} onClick={handleClick}><Link to={`/cd/${client.id}/d/dashboard`}>{shortenName(client.name)}</Link></div>
			})}
		</div>
	</div>
};


const UserMenu = ({ user }) => {
	return <div css={popupCss}>
		<div>
			<div className="title">{user.name}</div>
			<Link to="/auth/logout">Logout</Link>
		</div>
	</div>
};

const Side = props => {

	const auth = props.auth;
	// const currentClientId = props.currentClientId;

	const [cpRef, cpIsComponentVisible, spSetIsComponentVisible] = useComponentVisible(false);
	const [uRef, uIsComponentVisible, uSetIsComponentVisible] = useComponentVisible(false);

	let currentClientId = props.currentClientId;

	return (
		<div css={sideCss}>
			<nav>
				<ul>
					<li><Link to="/">Home</Link></li>
					{/* <li><Link to="/scan/delivery">Deliveries</Link></li> */}
					{(props.auth.user.role === "admin" || userHasMultipleClients(props.auth.user)) && <li ref={cpRef}>{cpIsComponentVisible && <ClientPickerMenu handleClick={() => {
						spSetIsComponentVisible(false);
					}} />}<span className="trigger" onClick={() => {
						spSetIsComponentVisible(!cpIsComponentVisible);
					}}>Client Picker</span></li>}

					{currentClientId !== undefined && <li>
						<Link to={`/cd/${currentClientId !== '' ? (currentClientId + '/') : ''}d/deliveries`}>
							<img src="/assets/img/svg/search.svg" alt="search" />
						</Link>
					</li>}

					<li ref={uRef} onClick={() => {
						uSetIsComponentVisible(!uIsComponentVisible)
					}}>{uIsComponentVisible && <UserMenu user={auth.user} />}
						<img className="trigger" src="/assets/img/svg/user.svg" alt="user" />
					</li>
				</ul>
			</nav>
		</div>
	);
}

export default Side;