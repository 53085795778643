let authenticationURI = 'http://localhost:5100/api';
let baseURI = 'http://localhost:5100/api';
let customerFrontURI = 'http://localhost:5100/api';
let publicURI = 'http://localhost:5001/api';
let internalAppURI = 'http://localhost:5001/api';
let cookieName = 'hds-dashboard';
let cookieDomain = undefined;
let siteURI = 'http://localhost:3600';
let betaURI = 'http://localhost:3600';
let s3ImageBase = 'https://images.homedelivery.com.au';
let adionaDashboardUri = '';
let allowEmployeeSSO = true;

if (process.env.REACT_APP_AUTH_URI) {
    authenticationURI = process.env.REACT_APP_AUTH_URI;
}

if (process.env.REACT_APP_BASE_URI) {
    baseURI = process.env.REACT_APP_BASE_URI;
}

if (process.env.REACT_APP_CUSTOMER_FRONT_URI) {
    customerFrontURI = process.env.REACT_APP_CUSTOMER_FRONT_URI;
}

if (process.env.REACT_APP_COOKIE_NAME) {
    cookieName = process.env.REACT_APP_COOKIE_NAME;
}

if (process.env.REACT_APP_COOKIE_DOMAIN) {
    cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;
}

if (process.env.REACT_APP_URI) {
    siteURI = process.env.REACT_APP_URI;
}

if (process.env.REACT_APP_BETA_URI) {
    betaURI = process.env.REACT_APP_BETA_URI;
}

if (process.env.REACT_APP_S3_PATH) {
    s3ImageBase = process.env.REACT_APP_S3_PATH;
}

if (process.env.REACT_APP_PUBLIC_URI) {
    publicURI = process.env.REACT_APP_PUBLIC_URI;
}

if (process.env.REACT_APP_INTERNAL_APP_URI) {
    internalAppURI = process.env.REACT_APP_INTERNAL_APP_URI;
}

if (process.env.REACT_APP_ADIONA_DASHBOARD_URI) {
    adionaDashboardUri = process.env.REACT_APP_ADIONA_DASHBOARD_URI;
}

if (process.env.REACT_APP_ALLOW_EMPLOYEE_SSO) {
    allowEmployeeSSO = process.env.REACT_APP_ALLOW_EMPLOYEE_SSO == "true";
}

export default {
    cookieName: cookieName,
	cookieDomain: cookieDomain,
    siteURI: siteURI,
	betaURI: betaURI,
    authenticationURI: authenticationURI,
    baseURI: baseURI,
    publicURI: publicURI,
    internalAppURI: internalAppURI,
    customerFrontURI: customerFrontURI,
    googleMapsKey: 'AIzaSyA6ToXBt04qLqUJzjfYuWnfL9pqAMmIgis',
    HEREAppId: '9hJLdCNhjRsHN073eK49',
    HEREKey: '8tz05SX35KzBHFrcmo4WWVocOPKb2eZkzqxgzlEjoJU',
    s3ImageBase: s3ImageBase,
	adionaDashboardUri: adionaDashboardUri,
    imageBase: "",
    bannerBase: "banners",
    mapProvider: 'here',
    allowEmployeeSSO: allowEmployeeSSO
}
