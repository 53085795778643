import * as React from 'react';
import { Link } from 'react-router-dom';
import { matchPath } from 'react-router';

/* @jsxImportSource @emotion/react */  import { css, jsx } from '@emotion/react'; /* eslint-disable-line */

import theme from 'app/theme';

import Breadcrumb from './Breadcrumb';
import Side from './Side';

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import registerClientRedux from 'app/resources/client';
import {usersCurrentClientId} from '../../util/hasAccess';

const headerCss = css`
	padding: 15px 40px 15px 20px;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(178deg,#00adee 0,#002bbb 100%);
    box-shadow: 0 3px 7px 0 rgba(0,0,0,.35);
	
	> div {		
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: auto;
	}

	.logo {
		height: 30px;
	}
`;


const clientRedux = registerClientRedux('CLIENT_LIST', ['LIST']);

const Header = props => {

	const pathname = props.location.pathname;
	const authedUsername = props.auth.user && props.auth.user.name;
	
	const auth = useSelector(state => state.auth);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			clientRedux.actionCreators.list(auth, {
				includeAll: true,
				limit: 1000,
				sort: 'name',
				inline: 'configuration,integrationList'
			})
		);
	}, [auth.user.id]);


    const match = matchPath(props.location.pathname, {
        path: '/cd/:clientId',
        exact: false,
        strict: false
	});
	
	const currentClientId = usersCurrentClientId(auth.user, (match?.params.clientId ? parseInt(match?.params.clientId) : undefined));

	return (
		<header>
			<div css={headerCss}>
				<div>
					<Link to="/">
						<img className="logo" src="/assets/arctech-logo.png" alt="" />
					</Link>
					<Side auth={props.auth} currentClientId={currentClientId} />
				</div>
			</div>
			{/* <Breadcrumb /> */}
		</header>
	);
}

export default Header;