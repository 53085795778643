import rest from 'lib/api/rest';
import actionCreatorsFor from 'lib/api/actionCreatorsFor';
import reducersFor from 'lib/api/reducersFor';
import reducerRegistry from 'lib/reducerRegistry';

import formatDate from 'date-fns/format';
import parseDate from 'date-fns/parse';

import config from 'app/config';

const onReceive = (data) => {
    return data;
};

const onSend = (data) => {
    return data;
};

const path = (id, params, token) => {
    return `${config.baseURI}/nv/state`;
};

export const fields = [
    {
        id: "id",
        name: "Identifier",
        isSortable: true,
        isVisibleDefault: true,
        filterType: "number"
    }
]

export default function registerRedux(storePath, types) {

    const reducers = reducersFor(storePath, types);

    reducerRegistry.register(storePath, reducers);

    return {
        actionCreators: actionCreatorsFor(storePath, types, rest(path, onReceive, onSend), {
            hasMeta: true,
        }),
        resourceName: "State",
        storePath: storePath,
        reducers: reducers,
        registerAdditional: registerRedux
    }

}
