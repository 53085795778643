import {
    LOGIN_USER_REQUEST,
    LOGIN_USER_FAILURE,
    LOGIN_USER_SUCCESS
} from '../constants/Authentication';

import Cookies from 'js-cookie';

import { verifyToken } from './VerifyToken';
import axios from 'axios';

import config from 'app/config';

export function loginUserSuccess(token) {
    return {
        type: LOGIN_USER_SUCCESS,
        payload: {
            token: token
        }
    }
}

export function loginUserFailure(error) {
    return {
        type: LOGIN_USER_FAILURE,
        payload: {
            status: error.response.status,
            loginDisplayMessage: error.response.loginDisplayMessage,
            reference: error.response.reference,
            code: error.response.code
        }
    }
}

export function loginUserRequest() {
    return {
        type: LOGIN_USER_REQUEST
    }
}

export function setUserToken(authToken) {

    let date = new Date();
    date.setHours(date.getHours() + 100);

    Cookies.set(config.cookieName, authToken, {
        path: '/',
        expires: date,
        secure: config.secureCookies === true ? true : false,
        sameSite: config.secureCookies === true ? 'Strict': 'Lax',
        cookieDomain: config.cookieDomain
    });

    return function (dispatch) {
        dispatch(loginUserSuccess(authToken));
        dispatch(verifyToken(authToken));

        return;
    }
};

export function loginUserWithFlexIdentity(token, flexToken) {
    return function (dispatch) {
        dispatch(loginUserRequest());

        axios.post(config.authenticationURI + '/flex/authenticate', {
            userIdentity: token,
            flexToken: flexToken
        }).then((x) => {
            if (x.status === 200 && x.data.access_token !== null) {

                let date = new Date();
                date.setHours(date.getHours() + 100);

                // Cookies.set(config.cookieName, x.data.access_token, {
                //     path: '/',
                //     expires: date,
                //     secure: config.secureCookies === true ? true : false,
                //     sameSite: config.secureCookies === true ? 'Strict': 'Lax',
                //     cookieDomain: config.cookieDomain
                // });

                dispatch(loginUserSuccess(x.data.access_token));
                dispatch(verifyToken(x.data.access_token));
            } else {
                console.log("DISPATCH USER FALIURE");
            }
        }).catch(error => {

            let errorStatus = '';
            let loginDisplayMessage = '';
            let reference = '';
            let code = '';

            if (error.message === 'Network Error') {
                errorStatus = 'NETWORK_ERROR';
                loginDisplayMessage = 'A network error occurred. Please try again later.'
            } else {
                errorStatus = error.response.data.errors[0].type;
                loginDisplayMessage = error.response.data.errors[0].displayMessage;
                code = error.response.data.errors[0].code;
                reference = error.response.data.errors[0].reference;
            }

            dispatch(loginUserFailure({
                response: {
                    status: errorStatus,
                    loginDisplayMessage: loginDisplayMessage,
                    code: code,
                    reference: reference
                }
            }));

            return {
                status: errorStatus,
                loginDisplayMessage: loginDisplayMessage,
                code: code,
                reference: reference
            }
        });

        return;
    }
}