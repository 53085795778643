import NavLink from '../components/SiteNavLink';
import uuid from 'app/util/uuid';

class SiteNavLinkBuilder {
    constructor(user_role, currentClientId, currentDate, userHasMultipleClients) {
        this.links = {
            "DashboardLink": {
                appDomain: 'beta',
                uri: `/d/overview`,
                label: {
                    old: "Dashboard",
                    new: "Overview"
                },
                icon: {
                    old: "fas fa-tachometer-alt"
                }
            },
            "DeliveriesSummaryLink": {
                appDomain: 'beta',
                uri: `/d/rg-summary`,
                uriRegex: [
                    /\/d\/rg-summary/g,
                ],
                label: {
                    old: "Deliveries Summary",
                    new: "Deliveries Summary"
                },
                icon: {
                    old: "fas fa-tachometer-alt",
                    new: "fa-regular fa-clipboard-list"
                }
            },
            "DeliveryOnTimeLink": {
                uri: `/cd/${(user_role === "admin" ? currentClientId + "/" : "")}d/deliveries-on-time`,
                label: {
                    old: "Delivery On Time",
                },
                icon: {
                    old: "fas fa-tachometer-alt",
                    new: "fas fa-truck-fast",
                }
            },
            "YouFoodzPickingSummaryLink": {
                uri: `/cd/d/youfoodz-picking-summary`,
                label: {
                    old: "Youfoodz Picking Summary",
                },
                icon: {
                    old: "fas fa-tachometer-alt"
                }
            },
            "ProvidoorSummaryLink": {
                uri: `/cd/d/providoor-summary`,
                label: {
                    old: "Providoor Summary",
                },
                icon: {
                    old: "fas fa-utensils"
                }
            },
            "ProvidoorForecastDeliveriesLink": {
                uri: `/cd/d/providoor-forecast-deliveries`,
                label: {
                    old: "Providoor Forecast Deliveries",
                    new: "Providoor Forecast",
                },
                icon: {
                    old: "fas fa-utensils"
                }
            },
            "PrimaryConnectSummaryLink": {
                uri: `/cd/d/primary-connect-summary`,
                label: {
                    old: "Primary Connect Summary",
                },
                icon: {
                    old: "fas fa-utensils"
                }
            },
            "PrimaryConnectForecastDeliveriesLink": {
                uri: `/cd/d/primary-connect-forecast-deliveries`,
                label: {
                    old: "Primary Connect Forecast Deliveries",
                },
                icon: {
                    old: "fas fa-utensils"
                }
            },
            "AllClientForecastDeliveriesLink": {
                uri: `/cd/d/all-forecast-deliveries`,
                label: {
                    old: "All Client Forecast Deliveries",
                    new: "Forecasts"
                },
                icon: {
                    old: "fas fa-utensils",
                    new: "fa-regular fa-chart-line-up",
                }
            },
            "DCDashboardLink": {
                uri: `/cd/${(user_role === "admin" ? currentClientId + "/" : "")}d/dc-dashboard`,
                label: {
                    old: "DC Dashboard",
                },
                icon: {
                    old: "fa fa-tachometer-alt"
                }
            },
            "DriversLink": {
                uri: `/ct/d`,
                label: {
                    old: "Drivers",
                },
                icon: {
                    old: "far fa-calendar-alt"
                }
            },
            "DriverSessionsLink": {
                uri: `/ct/ds`,
                label: {
                    old: "Driver Sessions",
                },
                icon: {
                    old: "far fa-calendar-alt",
                    new: "fas fa-truck-clock",
                }
            },
            "AdjustPayratesLink": {
                uri: `/ct/pay-rates`,
                label: {
                    old: "Adjust Payrates",
                },
                icon: {
                    old: "fas fa-dollar-sign"
                }
            },
            "DeliveryErrorsLink": {
                uri: `/cd/d/errors`,
                label: {
                    old: "Delivery Errors",
                    new: "Errors",
                },
                icon: {
                    old: "fas fa-exclamation-triangle"
                }
            },
            "RunDropErrorsLink": {
                appDomain: 'beta',
                uri: `/run/drop-errors`,
                uriRegex: [
                    /\/run\/drop-errors/g,
                ],
                label: {
                    old: "Run Drop Errors",
                    new: "Run Drop Errors",
                },
                icon: {
                    old: "fas fa-exclamation-triangle",
                    new: "fas fa-exclamation-triangle"
                },
                showBetaBadge: {
                    old: true,
                    new: true
                }
            },
            "ClientViewErrorsLink": {
                appDomain: 'beta',
                uri: `/run/view-errors`,
                uriRegex: [
                    /\/run\/view-errors/g,
                ],
                label: {
                    old: user_role === "admin" ? "Client View Errors" : "Errors",
                    new: user_role === "admin" ? "Client View Errors" : "Errors",
                },
                icon: {
                    old: "fas fa-exclamation-circle",
                    new: "fas fa-exclamation-circle"
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },
            "ErrorRateLink": {
                uri: `/cd/d/error-rate`,
                label: {
                    old: "Error Rate",
                },
                icon: {
                    old: "fas fa-exclamation-triangle",
                    new: "fas fa-exclamation-triangle",
                }
            },
            "ErrorFailureReportLink": {
                uri: `/cd/${(user_role === "admin" ? currentClientId + "/" : "")}d/error-and-failure-report`,
                label: {
                    old: "Error/Failure Report",
                    new: "Error / Fail Report"
                },
                icon: {
                    old: "fas fa-exclamation-triangle",
                    new: "fas fa-exclamation-triangle",
                }
            },
            "ExportShopifyLink": {
                uri: `/cd/d/export-shopify`,
                label: {
                    old: "Export Shopify",
                },
                icon: {
                    old: "fas fa-file-export"
                }
            },
            "ScanningReportLink": {
                uri: `/cd/d/scan-report`,
                label: {
                    old: "Scanning Report",
                    new: "Scan Report"
                },
                icon: {
                    old: "far fa-calendar-alt",
                    new: "fa-regular fa-scanner-touchscreen",
                }
            },
            "CriticalIssuesLink": {
                uri: `/cd/d/critical-issue`,
                label: {
                    old: "Critical Issues",
                },
                icon: {
                    old: "fas fa-exclamation-triangle",
                    new: "fas fa-hexagon-exclamation"
                }
            },
            "ReturnedtoDepotLink": {
                uri: `/cd/-1/d/return-to-depot`,
                label: {
                    old: "Returned to Depot",
                    new: "Return to Depot"
                },
                icon: {
                    old: "fas fa-exchange-alt",
                    new: "fa-regular fa-arrow-right-arrow-left",
                }
            },
            "RedeliveryBoxesLink": {
                appDomain: 'beta',
                uri: `/d/boxes-redelivery/all`,
                uriRegex: [
                    /\/d\/boxes-redelivery\/(.*)/g,
                ],
                label: {
                    old: "Redelivery Boxes",
                    new: "Redelivery Boxes"
                },
                icon: {
                    old: "fa-solid fa-retweet",
                    new: "fa-solid fa-retweet",
                },
                showBetaBadge: {
                    old: true,
                    new: true
                }
            },
            "WholesaleDeliveriesLink": {
                uri: `/cd/d/wholesale-deliveries`,
                label: {
                    old: "WS Deliveries Confirmation",
                    new: "WS Deliveries Confirmation"
                },
                icon: {
                    old: "fas fa-truck-loading",
                    new: "fas fa-conveyor-belt-boxes"
                }
            },
            "HomeDeliveriesConfirmLink": {
                uri: `/cd/d/home-deliveries-confirm`,
                label: {
                    old: "Home Deliveries Confirmation",
                    new: "Home Deliveries Confirmation"
                },
                icon: {
                    old: "fas fa-truck-loading",
                    new: "fas fa-truck-loading"
                }
            },
            "TemperatureCheckConfirmationLink": {
                appDomain: 'beta',
                uri: `/run/run-events/temp-check-confirmation`,
                uriRegex: [
                    /\/run\/run-events\/temp-check-confirmation/g,
                ],
                label: {
                    old: "Temp Check Confirmation",
                    new: "Temp Check Confirmation"
                },
                icon: {
                    old: "fas fa-thermometer-quarter",
                    new: "fas fa-thermometer-quarter"
                }
            },
            "DropInstructionsFollowedLink": {
                appDomain: 'beta',
                uri: `/run/drops-instructions-followed`,
                uriRegex: [
                    /\/run\/drops-instructions-followed/g,
                ],
                label: {
                    old: "Drop Instructions Followed",
                    new: "Drop Instructions Followed"
                },
                icon: {
                    old: "fas fa-note",
                    new: "fas fa-note"
                }
            },
            "ZoneLookupLink": {
                appDomain: 'beta',
                uri: `/z/lookup`,
                uriRegex: [
                    /\/z\/lookup/g,
                ],
                label: {
                    old: "Zone Lookup",
                    new: "Zone Lookup"
                },
                icon: {
                    old: "fas fa-map",
                    new: "fas fa-map"
                }
            },
            "CustomerChangeRequestsLink": {
                uri: `/cd/d/delivery-notes-changes`,
                label: {
                    old: "Customer Change Requests",
                    new: "Customer Change Requests"
                },
                icon: {
                   old: "fas fa-user-edit"
                }
            },
            "CustomerChangeRequestsLinkBeta": {
                appDomain: 'beta',
                uri: `/d/customer-change/all`,
                uriRegex: [
                    /\/d\/customer-change\/(.*)/g,
                ],
                label: {
                    old: "Customer Change Requests (Beta)",
                    new: "Customer Change Requests (Beta)"
                },
                icon: {
                   old: "fas fa-user-edit"
                }
            },
            "SMSScheduleLink": {
                uri: `/sms`,
                label: {
                    old: "SMS Schedule",
                    new: "SMS Schedule",
                },
                icon: {
                    old: "fas fa-sms",
                    new: "fa fa-comment-sms"
                }
            },
            "SMSLogLink": {
                uri: `/cd/d/sms-log`,
                label: {
                    old: "SMS Log",
                },
                icon: {
                    old: "fas fa-sms",
                    new: "fas fa-messages",
                }
            },
            "SMSBlockLink": {
                appDomain: 'beta',
                uri: `/messaging/sms-block/all`,
                uriRegex: [
                    /\/messaging\/sms-block\/(.*)/g,
                ],
                label: {
                    old: "SMS Number Block",
                    new: "SMS Number Block",
                },
                icon: {
                    old: "fas fa-ban",
                    new: "fa fa-ban"
                }
            },
            "UnsubscribeEmailLink": {
                appDomain: 'beta',
                uri: `/messaging/email-unsubscribes/all`,
                uriRegex: [
                    /\/messaging\/email-unsubscribes\/(.*)/g,
                ],
                label: {
                    old: "Email Unsubscribe",
                    new: "Email Unsubscribe",
                },
                icon: {
                    old: "fas fa-ban",
                    new: "fa fa-ban"
                }
            },
            "DriverMessagesLink": {
                uri: `/ct/d/messages`,
                label: {
                    old: "Driver Messages",
                },
                icon: {
                    old: "fas fa-comment-dots"
                }
            },
            "WebhooksLink": {
                appDomain: 'beta',
                uri: `/d/webhooks/all`,
                label: {
                    old: "Webhooks",
                },
                icon: {
                    old: "fas fa-globe-asia",
                    new: "fas fa-earth-asia",
                }
            },
            "UploadCSVLink": {
                uri: `/nv/deliveries/upload`,
                label: {
                    old: "Upload CSV",
                    new: "Upload Deliveries"
                },
                icon: {
                    old: "fas fa-cloud-upload-alt"
                },
                tooltip: {
                    old: "This page is getting deprecated",
                    new: "This page is getting deprecated",
                },
                tooltipColor: 'red'
            },
            "LegacyOrderSearchLink": {
                uri: `/cd/${(user_role === "admin" ? currentClientId + "/" : "")}d/deliveries`,
                label: {
                    old: "Order Search",
                    new: "Order Search"
                },
                icon: {
                    old: "fas fa-search"
                }
            },
            "OrderSearchLink": {
                appDomain: 'beta',
                uri: `/d/deliveriesN/all`,
                uriRegex: [
                    /\/d\/deliveriesN\/(.*)/g,
                ],
                label: {
                    old: "Order Search",
                    new: "Order Search"
                },
                icon: {
                    old: "fas fa-search"
                }
            },
            "OrderETALink": {
                uri: `/cd/${(user_role === "admin" ? currentClientId + "/" : "")}d/eta`,
                label: {
                    old: "Time Window Report",
                    new: "ETA"
                },
                icon: {
                    old: "fas fa-clock"
                }
            },
            "NewOrderETALink": {
                uri: `/d/time-window-report`,
                appDomain: 'beta',
                label: {
                    old: "Time Window Report",
                    new: "Time Window Report"
                },
                icon: {
                    old: "fas fa-clock"
                }
            },
            "ClientSummaryReportLink": {
                uri: `/cd/${(user_role === "admin" ? currentClientId + "/" : "")}d/client-summary`,
                label: {
                    old: "Client Summary Report",
                },
                icon: {
                    old: "fas fa-cog"
                }
            },
            "ZoneSuburbReportLink": {
                uri: `/cd/${(user_role === "admin" ? currentClientId + "/" : "")}d/zone-suburb-report`,
                label: {
                    old: "Zone & Suburb Report",
                    new: "Zone Summary"
                },
                icon: {
                    old: "fas fa-map-marked",
                    new: "fa fa-map-location-dot",
                }
            },
            "CustomerNotificationSettingsLink": {
                uri: `/cs/${(user_role === "admin" ? currentClientId + "/cns" : "cns")}`,
                label: {
                    old: "Customer Notification Settings",
                },
                icon: {
                    old: "fas fa-user-edit"
                }
            },
            "RoutingSummaryLink": {
                uri: `/cd/-1/d/routing-summary`,
                label: {
                    old: "Routing Summary",
                    new: "Route Summary"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-route",
                }
            },
            "AdionaSyncLink": {
                uri: `/cd/-1/d/adiona`,
                label: {
                    old: "Adiona Sync",
                    new: "Optimize Routes"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-route",
                },
                tooltip: {
                    old: "'Adiona Sync' are changing to 'Optimized Routes'",
                    new: "'Optimized Routes' was titled 'Adiona Sync'",
                }
            },
            "ManualRoutingLink": {
                appDomain: 'beta',
                uri: `/d/manual-routing/all`,
                uriRegex: [
                    /\/d\/manual-routing\/(.*)/g,
                ],
                showBetaBadge: {
                    old: true,
                    new: true
                },
                label: {
                    old: "Manual Routing",
                    new: "Manual Routing"
                },
                icon: {
                    old: "fas fa-route",
                    new: "fas fa-route",
                }
            },
            "RDSRoutingToolLink": {
                appDomain: 'beta',
                uri: `/d/visualisation/date/${currentDate}/rg/all/rz/all/z/all/client/all/service/rds/tw/all`,
                uriRegex: [
                    /\/d\/visualisation\/date\/(.*)\/rg\/(.*)\/rz\/(.*)\/z\/(.*)\/client\/(.*)\/service\/(.*)\/tw\/(.*)/g,
                ],
                showBetaBadge: {
                    old: true,
                    new: true
                },
                label: {
                    old: "RDS Routing Tool",
                    new: "RDS Routing Tool"
                },
                icon: {
                    old: "fas fa-route",
                    new: "fas fa-route",
                }
            },
            "PreDeliveriesLink": {
                uri: `/nv/pre-deliveries/all`,
                label: {
                    old: "Pre-Deliveries",
                    new: "Undated Deliveries"
                },
                icon: {
                    old: "fas fa-truck-loading",
                    new: "fa-regular fa-box"
                },
                tooltip: {
                    old: "'Pre-Deliveries' are changing to 'Undated Deliveries'",
                    new: "'Undated Deliveries' was titled 'Pre-Deliveries'",
                }
            },
            "PreDeliveriesManageLink": {
                uri: `/nv/pre-deliveries/promote`,
                label: {
                    old: "Pre-Deliveries Manage",
                    new: "Undated Deliveries Manage"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fa-regular fa-box"
                },
                tooltip: {
                    old: "'Pre-Deliveries Manage' are changing to 'Undated Deliveries Manage'",
                    new: "'Undated Deliveries Manage' was titled 'Pre-Deliveries Manage'",
                }
            },
            "ApiStatsLink": {
                uri: `/nv/system/api-stats`,
                label: {
                    old: "API Stats",
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-chart-line"
                }
            },
            "WarehouseDashboardLink": {
                uri: `/nv/warehouse`,
                label: {
                    old: "Warehouse Dashboard",
                },
                icon: {
                    old: "fas fa-cog"
                }
            },
            "LocalitiesLink": {
                uri: `/nv/locality`,
                label: {
                    old: "Localities",
                },
                icon: {
                    old: "fas fa-layer-group"
                }
            },
            "ZonesLink": {
                uri: `/nv/zones`,
                label: {
                    old: "Zones",
                    new: "Zones",
                },
                icon: {
                    old: "fas fa-layer-group"
                }
            },
            "UndatedPrintLink": {
                uri: `/nv/deliveries/print`,
                label: {
                    old: "Undated Print",
                    new: "Print Undated orders"
                },
                icon: {
                    old: "fas fa-layer-group",
                    new: "fa-regular fa-print",
                }
            },
            "GeocodeReportLink": {
                uri: `/nv/zones/geocode-summary`,
                label: {
                    old: "Geocode Report",
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-clipboard-list"
                }
            },
            "BetaImportLink": {
                appDomain: 'beta',
                uri: `/d/delivery-upload`,
                uriRegex: [
                    /\/d\/delivery-upload/g,
                ],
                label: {
                    old: "Import Deliveries",
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-cloud-upload-alt"
                },
            },
            "ClientsLink": {
                appDomain: 'beta',
                uri: `/c/clients/all`,
                uriRegex: [
                    /\/c\/clients\/(.*)/g,
                ],
                label: {
                    old: "Clients",
                    new: "Clients",
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-user-group"
                }
            },
            "DepotsLink": {
                appDomain: 'beta',
                uri: `/z/depots/all`,
                uriRegex: [
                    /\/z\/depots\/(.*)/g,
                ],
                label: {
                    old: "Depots",
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-garage"
                }
            },
            "BoxSizesLink": {
                appDomain: 'beta',
                uri: `/d/box-sizes/all`,
                uriRegex: [
                    /\/d\/box-sizes\/(.*)/g,
                ],
                label: {
                    old: "Box Sizes",
                },
                icon: {
                    old: "fas fa-ruler",
                    new: "fas fa-ruler"
                }
            },
            "BetaContractorAssignLink": {
                appDomain: 'beta',
                uri: `/run/assign/contractor/1/${currentDate}/rg/all/all`,
                uriRegex: [
                    /\/run\/assign\/contractor\/1\/(.*)\/rg\/(.*)\/(.*)/g,
                    /\/run\/assign\/contractorN\/1\/(.*)\/rg\/(.*)\/(.*)/g,
                ],
                label: {
                    old: "Contractor Assign",
                    new: "Assign Contractor"
                },
                icon: {
                    old: "fas fa-truck"
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "BetaDriverAssignLink": {
                appDomain: 'beta',
                uri: `/run/assign/driver/1/${currentDate}/rg/all/all`,
                uriRegex: [
                    /\/run\/assign\/driver\/1\/(.*)\/rg\/(.*)\/(.*)/g,
                    /\/run\/assign\/driverN\/1\/(.*)\/rg\/(.*)\/(.*)/g,
                ],
                label: {
                    old: "Driver Assign",
                    new: "Assign Driver"
                },
                icon: {
                    old: "fas fa-truck"
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "BetaVanAvailabilityLink": {
                appDomain: 'beta',
                uri: `/ctr/van-availability/grid`,
                uriRegex: [
                    /\/ctr\/van-availability\/grid/g,
                ],
                label: {
                    old: "Van Availability",
                    new: "Van Availability"
                },
                icon: {
                    old: "fas fa-truck",
                    new: "fas fa-truck-clock",
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "BetaVanAvailabilityReportLink": {
                appDomain: 'beta',
                uri: `/ctr/van-availability/report`,
                uriRegex: [
                    /\/ctr\/van-availability\/report/g,
                ],
                label: {
                    old: "Van Availability Report",
                    new: "Van Availability Report"
                },
                icon: {
                    old: "fas fa-truck",
                    new: "fas fa-truck-clock",
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "VanAvailabilityExcessLink": {
                appDomain: 'beta',
                uri: `/ctr/van-availability/excess`,
                uriRegex: [
                    /\/ctr\/van-availability\/excess/g,
                ],
                label: {
                    old: "Van Availability Excess",
                    new: "Van Availability Excess"
                },
                icon: {
                    old: "fas fa-truck",
                    new: "fas fa-truck-clock",
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },
            "DriverErrorsReportLink": {
                appDomain: 'beta',
                uri: `/run/errors/report`,
                uriRegex: [
                    /\/run\/errors\/report/g,
                ],
                label: {
                    old: "Driver Errors Report",
                    new: "Driver Errors Report"
                },
                icon: {
                    old: "fas fa-chart-line",
                    new: "fas fa-chart-line",
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "ContractorPerformanceReportLink": {
                appDomain: 'beta',
                uri: `/ctr/reports/contractors-performance`,
                uriRegex: [
                    /\/ctr\/reports\/contractors-performance/g,
                ],
                label: {
                    old: "Contractor Performance Report",
                    new: "Contractor Performance Report"
                },
                icon: {
                    old: "fas fa-chart-line",
                    new: "fas fa-chart-line",
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "DriverPerformanceReportLink": {
                appDomain: 'beta',
                uri: `/ctr/reports/drivers-performanceN`,
                uriRegex: [
                    /\/ctr\/reports\/drivers-performanceN/g,
                ],
                label: {
                    old: "Driver Performance Report",
                    new: "Driver Performance Report"
                },
                icon: {
                    old: "fas fa-chart-line",
                    new: "fas fa-chart-line",
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "DriverAppVersionReportLink": {
                appDomain: 'beta',
                uri: `/ctr/reports/app-versions`,
                uriRegex: [
                    /\/ctr\/reports\/app-versions/g,
                ],
                label: {
                    old: "Driver App Versions Report",
                    new: "Driver App Versions Report"
                },
                icon: {
                    old: "fas fa-code-branch",
                    new: "fas fa-code-branch",
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "DotWMSLogsLink": {
                appDomain: 'beta',
                uri: `/wms/logs/all`,
                uriRegex: [
                    /\/wms\/logs\/(.*)/g,
                ],
                label: {
                    old: "DotWMS Logs",
                    new: "DotWMS Logs"
                },
                icon: {
                    old: "fa-sharp fa-regular fa-square-list",
                    new: "fa-sharp fa-regular fa-square-list",
                },
                showBetaBadge: {
                    old: true,
                    new: true
                }
            },
            "InventoryStockAdjustmentsLink": {
                appDomain: 'beta',
                uri: `/wh/stock-adjustment/all`,
                uriRegex: [
                    /\/wh\/stock-adjustment\/(.*)/g,
                ],
                label: {
                    old: "Inventory Stock Adjustments",
                    new: "Inventory Stock Adjustments"
                },
                icon: {
                    old: "fas fa-warehouse-full",
                    new: "fas fa-warehouse-full",
                },
                showBetaBadge: {
                    old: true,
                    new: true
                }
            },
            "RDSReconcile": {
                appDomain: 'beta',
                uri: `/rds/reconcile/all`,
                uriRegex: [
                    /\/rds\/reconcile\/(.*)/g,
                ],
                label: {
                    old: "RDS Reconcile",
                    new: "RDS Reconcile"
                },
                icon: {
                    old: "fas fa-warehouse-full",
                    new: "fas fa-warehouse-full",
                },
                showBetaBadge: {
                    old: true,
                    new: true
                }
            },
            "LineHaulMovementsLink": {
                appDomain: 'beta',
                uri: `/lineHaul/movements/all`,
                uriRegex: [
                    /\/lineHaul\/movements\/(.*)/g,
                ],
                label: {
                    old: "Movements",
                    new: "Movements"
                },
                icon: {
                    old: "fas fa-duck",
                    new: "fas fa-duck",
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "LineHaulMovementsAllocatorLink": {
                appDomain: 'beta',
                uri: `/lineHaul/movements/allocator`,
                uriRegex: [
                    /\/lineHaul\/movements\/allocator/g,
                ],
                label: {
                    old: "Line Haul Allocator",
                    new: "Line Haul Allocator"
                },
                icon: {
                    old: "fas fa-duck",
                    new: "fas fa-duck",
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "LineHaulMovementsBuilderLink": {
                appDomain: 'beta',
                uri: `/lineHaul/movements/builder`,
                uriRegex: [
                    /\/lineHaul\/movements\/builder/g,
                ],
                label: {
                    old: "Line Haul Builder",
                    new: "Line Haul Builder"
                },
                icon: {
                    old: "fas fa-duck",
                    new: "fas fa-duck",
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "LineHaulJobsLink": {
                appDomain: 'beta',
                uri: `/lineHaul/jobs/all`,
                uriRegex: [
                    /\/lineHaul\/jobs\/(.*)/g,
                ],
                label: {
                    old: "Jobs",
                    new: "Jobs"
                },
                icon: {
                    old: "fas fa-duck",
                    new: "fas fa-duck",
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "LineHaulJobAcceptanceLink": {
                appDomain: 'beta',
                uri: `/lineHaul/jobs/acceptance`,
                uriRegex: [
                    /\/lineHaul\/jobs\/acceptance/g,
                ],
                label: {
                    old: "Job Acceptance",
                    new: "Job Acceptance"
                },
                icon: {
                    old: "fas fa-duck",
                    new: "fas fa-duck",
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "LineHaulJobCreateLink": {
                appDomain: 'beta',
                uri: `/lineHaul/jobs/create`,
                uriRegex: [
                    /\/lineHaul\/jobs\/create/g,
                ],
                label: {
                    old: "Job Create",
                    new: "Job Create"
                },
                icon: {
                    old: "fas fa-duck",
                    new: "fas fa-duck",
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "LineHaulScoreboardLink": {
                appDomain: 'beta',
                uri: `/lineHaul/jobs/scoreboard`,
                uriRegex: [
                    /\/lineHaul\/jobs\/scoreboard/g,
                ],
                label: {
                    old: "Job Scoreboard",
                    new: "Job Scoreboard"
                },
                icon: {
                    old: "fas fa-duck",
                    new: "fas fa-duck",
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "LineHaulTruckLink": {
                appDomain: 'beta',
                uri: `/lineHaul/trucks/all`,
                uriRegex: [
                    /\/lineHaul\/trucks\/(.*)/g,
                ],
                label: {
                    old: "Trucks",
                    new: "Trucks"
                },
                icon: {
                    old: "fas fa-duck",
                    new: "fas fa-duck",
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "LineHaulTrailerLink": {
                appDomain: 'beta',
                uri: `/lineHaul/trailers/all`,
                uriRegex: [
                    /\/lineHaul\/trailers\/(.*)/g,
                ],
                label: {
                    old: "Trailers",
                    new: "Trailers"
                },
                icon: {
                    old: "fas fa-duck",
                    new: "fas fa-duck",
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "LineHaulSubcontractorLink": {
                appDomain: 'beta',
                uri: `/lineHaul/subcontractors/all`,
                uriRegex: [
                    /\/lineHaul\/subcontractors\/(.*)/g,
                ],
                label: {
                    old: "Subcontractors",
                    new: "Subcontractors"
                },
                icon: {
                    old: "fas fa-duck",
                    new: "fas fa-duck",
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "LineHaulDollyLink": {
                appDomain: 'beta',
                uri: `/lineHaul/dolly/all`,
                uriRegex: [
                    /\/lineHaul\/dolly\/(.*)/g,
                ],
                label: {
                    old: "Dollys",
                    new: "Dollys"
                },
                icon: {
                    old: "fas fa-duck",
                    new: "fas fa-duck",
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "LineHaulLegLink": {
                appDomain: 'beta',
                uri: `/lineHaul/legs/all`,
                uriRegex: [
                    /\/lineHaul\/legs\/(.*)/g,
                ],
                label: {
                    old: "Legs",
                    new: "Legs"
                },
                icon: {
                    old: "fas fa-duck",
                    new: "fas fa-duck",
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "WarehouseInventoryLink": {
                appDomain: 'beta',
                uri: `/wh/inventory/all`,
                uriRegex: [
                    /\/wh\/inventory\/(.*)/g,
                ],
                label: {
                    old: "Warehouse Inventory",
                    new: "Inventory"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-warehouse-full"
                },
                tooltip: {
                    old: "'Warehouse Inventory' are changing to 'Inventory'",
                    new: "'Inventory' was titled 'Warehouse Inventory'",
                }
            },
            "WarehousesLink": {
                appDomain: 'beta',
                uri: `/wh/warehouses/all`,
                uriRegex: [
                    /\/wh\/warehouses\/(.*)/g,
                ],
                label: {
                    old: "WMS Warehouses",
                    new: "WMS Warehouses"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-warehouse-full"
                }
            },
            "WMSProductsLink": {
                appDomain: 'beta',
                uri: `/wms/products/all`,
                uriRegex: [
                    /\/wms\/products\/(.*)/g,
                ],
                label: {
                    old: "WMS Products",
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-warehouse-full"
                }
            },
            "WMSLogsLink": {
                appDomain: 'beta',
                uri: `/wms/logs/all`,
                uriRegex: [
                    /\/wms\/logs\/(.*)/g,
                ],
                label: {
                    old: "WMS Logs",
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-warehouse-full"
                }
            },
            "BetaAPReportLink": {
                appDomain: 'beta',
                uri: `/r/auspost`,
                uriRegex: [
                    /\/r\/auspost/g,
                ],
                label: {
                    old: "AP Report",
                },
                icon: {
                    old: "fas fa-cog",
                },
                showBetaBadge: {
                    old: true,
                    new: true
                }
            },
            "BetaWMSOrderItemsLink": {
                appDomain: 'beta',
                uri: `/d/dotwms-order-items/all`,
                uriRegex: [
                    /\/d\/dotwms-order-items\/(.*)/g,
                ],
                label: {
                    old: "WMS Order Items",
                    new: "WMS Order Items"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-warehouse-full"
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "BetaWMSSyncedDeliveriesLink": {
                appDomain: 'beta',
                uri: `/d/dotwms-synced-deliveries/all`,
                uriRegex: [
                    /\/d\/dotwms-synced-deliveries\/(.*)/g,
                ],
                label: {
                    old: "WMS Synced Deliveries",
                    new: "WMS order sync"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-rotate"
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "BetaDeliveriesLink": {
                appDomain: 'beta',
                uri: `/d/deliveries/all`,
                uriRegex: [
                    /\/d\/deliveries\/(.*)/g,
                ],
                label: {
                    old: "Deliveries",
                    new: "Deliveries"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-box"
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "BetaDeliveriesGeoIssueLink": {
                appDomain: 'beta',
                uri: `/d/deliveries-geo-issues/all`,
                uriRegex: [
                    /\/d\/deliveries-geo-issues\/(.*)/g,
                ],
                label: {
                    old: "Deliveries Geo Issues",
                    new: "Deliveries Geo Issues"
                },
                icon: {
                    old: "fa-solid fa-location-dot-slash",
                    new: "fa-solid fa-location-dot-slash"
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "CustomerFeedbackLink": {
                appDomain: 'beta',
                uri: `/d/customer-feedback/all`,
                uriRegex: [
                    /\/d\/customer-feedback\/(.*)/g,
                ],
                label: {
                    old: "Customer Feedback",
                },
                icon: {
                    old: "fas fa-bullhorn",
                    new: "fas fa-bullhorn"
                }
            },
            "CustomerFeedbackNLink": {
                appDomain: 'beta',
                uri: `/d/customer-feedbackN/all`,
                uriRegex: [
                    /\/d\/customer-feedbackN\/(.*)/g,
                ],
                label: {
                    old: "Customer Feedback New",
                },
                icon: {
                    old: "fas fa-bullhorn",
                    new: "fas fa-bullhorn"
                }
            },
            "BetaBoxesLink": {
                appDomain: 'beta',
                uri: `/d/boxes/all`,
                uriRegex: [
                    /\/d\/boxes\/(.*)/g,
                ],
                label: {
                    old: "Boxes",
                    new: "Delivery Boxes"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-boxes"
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "BetaStartrackBoxesLink": {
                appDomain: 'beta',
                uri: `/d/startrack-boxes/all`,
                uriRegex: [
                    /\/d\/startrack-boxes\/(.*)/g,
                ],
                label: {
                    old: "Startrack Boxes",
                    new: "Startrack Boxes"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-boxes"
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "AuspostManifestsLink": {
                appDomain: 'beta',
                uri: `/d/auspost-orders/all`,
                uriRegex: [
                    /\/d\/auspost-orders\/(.*)/g,
                ],
                label: {
                    old: "Auspost Manifests",
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-clipboard-list",
                }
            },
            "AuspostDataReportLink": {
                appDomain: 'beta',
                uri: `/d/auspost-data/all`,
                uriRegex: [
                    /\/d\/auspost-data\/(.*)/g,
                ],
                label: {
                    old: "Auspost Data Report",
                    new: "Auspost Report"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-clipboard-list"
                }
            },

            "StartrackManifestsLink": {
                appDomain: 'beta',
                uri: `/d/startrack-orders/all`,
                uriRegex: [
                    /\/d\/startrack-orders\/(.*)/g,
                ],
                label: {
                    old: "Startrack Manifests",
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-clipboard-list",
                }
            },
            "StartrackDataReportLink": {
                appDomain: 'beta',
                uri: `/d/startrack-data/all`,
                uriRegex: [
                    /\/d\/startrack-data\/(.*)/g,
                ],
                label: {
                    old: "Startrack Data Report",
                    new: "Startrack Report"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-clipboard-list"
                }
            },

            "BilledWeightsUploadLink": {
                appDomain: 'beta',
                uri: `/d/billed-weights/upload`,
                uriRegex: [
                    /\/d\/build-weights\/upload/g,
                ],
                label: {
                    old: "Upload Billed Weights",
                    new: "Upload Billed Weights"
                },
                icon: {
                    old: "fas fa-weight-scale",
                    new: "fas fa-weight-scale"
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },

            "LCRLocalityLookupLink": {
                appDomain: 'beta',
                uri: `/carrier-routing/locality-lookup`,
                uriRegex: [
                    /\/carrier-routing\/locality-lookup\/*/g,
                ],
                label: {
                    old: "Locality Lookup",
                    new: "Locality Lookup"
                },
                icon: {
                    old: "fas fa-weight-scale",
                    new: "fas fa-weight-scale"
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },

            "PalletsLink": {
                appDomain: 'beta',
                uri: `/pallet/pallets/all`,
                uriRegex: [
                    /\/pallet\/pallets\/(.*)/g,
                ],
                label: {
                    old: "Pallets",
                    new: "Pallets"
                },
                icon: {
                    old: "fas fa-pallet",
                    new: "fas fa-pallet"
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },

            "PalletsSummaryLink": {
                appDomain: 'beta',
                uri: `/pallet/summary`,
                uriRegex: [
                    /\/pallet\/summary\/(.*)/g,
                ],
                label: {
                    old: "Pallets Summary",
                    new: "Pallets Summary"
                },
                icon: {
                    old: "fas fa-pallet",
                    new: "fas fa-pallet"
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },

            "AppLogLink": {
                appDomain: 'beta',
                uri: `/app/logs/all`,
                uriRegex: [
                    /\/app\/logs\/(.*)/g,
                ],
                label: {
                    old: "App Logs",
                    new: "App Logs"
                },
                icon: {
                    old: "fas fa-pallet",
                    new: "fas fa-pallet"
                },
                showBetaBadge: {
                    old: true,
                    new: true
                }
            },

            "BetaPurchaseOrdersLink": {
                appDomain: 'beta',
                uri: `/po/purchase-orders/all`,
                uriRegex: [
                    /\/po\/purchase-orders\/(.*)/g,
                ],
                label: {
                    old: "Purchase Orders",
                    new: "Purchase Orders"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-clipboard-list"
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "BetaAdvancedShippingNoticesLink": {
                appDomain: 'beta',
                uri: `/asn/advanced-shipping-notice/all`,
                uriRegex: [
                    /\/asn\/advanced-shipping-notice\/(.*)/g,
                ],
                label: {
                    old: "Advanced Shipping Notices",
                    new: "Advanced Shipping Notices"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-cog"
                },
                showBetaBadge: {
                    old: true,
                    new: true
                }
            },
            "BetaRDSLink": {
                appDomain: 'beta',
                uri: `/rds/c/all`,
                uriRegex: [
                    /\/rds\/c\/(.*)/g,
                ],
                label: {
                    old: "RDS",
                    new: "RDS Workflows"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-repeat"
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "BetaRDSStoresLink": {
                appDomain: 'beta',
                uri: `/rds/store/all`,
                uriRegex: [
                    /\/rds\/store\/(.*)/g,
                ],
                label: {
                    old: "RDS Stores",
                    new: "Stores"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-shop"
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "RDSStoresUploadLink": {
                appDomain: 'beta',
                uri: `/rds/store/upload`,
                uriRegex: [
                    /\/rds\/store\/upload/g,
                ],
                label: {
                    old: "Stores Upload",
                    new: "Stores Upload"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-shop"
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },
            "BetaRDSServiceProvidersLink": {
                appDomain: 'beta',
                uri: `/rds/service-provider/all`,
                uriRegex: [
                    /\/rds\/service-provider\/(.*)/g,
                ],
                label: {
                    old: "RDS Service Providers",
                    new: "Service Providers"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-shop"
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "RDSRunReport": {
                appDomain: 'beta',
                uri: `/rds/reports/run-report/all`,
                uriRegex: [
                    /\/rds\/reports\/run-report\/(.*)/g,
                ],
                label: {
                    old: "RDS Run Report",
                    new: "RDS Run Report"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-shop"
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "RetailSkillsLink": {
                appDomain: 'beta',
                uri: `/d/retail-skills/all`,
                uriRegex: [
                    /\/d\/retail-skills\/(.*)/g,
                ],
                label: {
                    old: "Retail Skills",
                    new: "Retail Skills"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-shop"
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "RetailStoresLink": {
                appDomain: 'beta',
                uri: `/d/retail-exceptions/manage/all`,
                uriRegex: [
                    /\/d\/retail-exceptions\/manage\/(.*)/g,
                ],
                label: {
                    old: "Retail Stores",
                    new: "Retail Stores"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-shop"
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "RetailStoresImportLink": {
                appDomain: 'beta',
                uri: `/d/retail-exceptions/upload`,
                uriRegex: [
                    /\/d\/retail-exceptions\/upload/g,
                ],
                label: {
                    old: "Retail Stores Upload",
                    new: "Retail Stores Upload"
                },
                icon: {
                    old: "fas fa-upload",
                    new: "fas fa-upload"
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "RetailStoresCreateExceptionLink": {
                appDomain: 'beta',
                uri: `/d/retail-exceptions`,
                uriRegex: [
                    /\/d\/retail-exceptions/g,
                ],
                label: {
                    old: "Retail Stores Exceptions",
                    new: "Retail Stores Exceptions"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-shop"
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "RetailStoresExceptionDailyReportLink": {
                appDomain: 'beta',
                uri: `/d/retail-exceptions/reports/daily`,
                uriRegex: [
                    /\/d\/retail-exceptions\/reports\/daily/g,
                ],
                label: {
                    old: "Retail Stores Daily Exceptions",
                    new: "Retail Stores Daily Exceptions"
                },
                icon: {
                    old: "fas fa-chart-simple",
                    new: "fas fa-chart-simple"
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "PrintOrdersLink": {
                uri: `/client-tools/${((user_role === "admin" || userHasMultipleClients) ? currentClientId + "/" : "")}orders/print-orders`,
                label: {
                    old: "Print Orders",
                    new: "Print Orders"
                },
                icon: {
                    new: "fas fa-print"
                }
            },
            "PrintPickupsLink": {
                uri: `/client-tools/${((user_role === "admin" || userHasMultipleClients) ? currentClientId + "/" : "")}orders/print-pickups`,
                label: {
                    old: "Print Pickups",
                },
                icon: {
                    old: null,
                    new: "fas fa-print"
                }
            },
            "ForecastRecipesLink": {
                uri: `/client-tools/${((user_role === "admin" || userHasMultipleClients) ? currentClientId + "/" : "")}orders/recipes`,
                label: {
                    old: "Forecast Recipes",
                    new: "Forecast Products"
                },
                icon: {
                    old: null,
                    new: "fas fa-chart-line-up"
                },
                tooltip: {
                    old: "'Forecast Recipes' are changing to 'Forecast Products'",
                    new: "'Forecast Products' was titled 'Forecast Recipes'",
                }
            },
            "ForecastDeliveriesLink": {
                appDomain: 'beta',
                // uri: `/client-tools/${((user_role === "admin" || userHasMultipleClients) ? currentClientId + "/" : "")}orders/forecast`,
                uri: '/d/client-tools/orders/forecast',
                uriRegex: [
                    /\/d\/client-tools\/orders\/forecast/g,
                ],
                label: {
                    old: "Forecast Deliveries",
                    new: "Forecast Deliveries"
                },
                icon: {
                    old: null,
                    new: "fas fa-chart-line-up"
                }
            },
            "PickSheetOrderLink": {
                uri: `/client-tools/${currentClientId + "/"}pick-sheet-order`,
                label: {
                    old: "Pick Sheet Order",
                    new: "Pick Sheet Sequence"
                },
                icon: {
                    old: null,
                    new: "fa-regular fa-arrow-up-small-big"
                }
            },
            "DocumentationLink": {
                uri: `/client-tools/docs-login`,
                label: {
                    old: "Documentation",
                },
                icon: {
                    old: null
                }
            },
            "SummaryReportLink": {
                uri: `/packer/${((user_role === "admin" || userHasMultipleClients) ? currentClientId + "/" : "")}reports/summary`,
                label: {
                    old: "Summary Report",
                    new: "Summary"
                },
                icon: {
                    old: "fas fa-tachometer-alt",
                    new: "fas fa-clipboard-list",
                }
            },
            "ProductSummaryReportLink": {
                uri: `/packer/${((user_role === "admin" || userHasMultipleClients) ? currentClientId + "/" : "")}reports/product-summary`,
                label: {
                    old: "Product Summary Report",
                    new: "Product Summary"
                },
                icon: {
                    old: "fas fa-tachometer-alt",
                    new: "fas fa-gauge-high",
                }
            },
            "PickerPerformanceReportsLink": {
                uri: `/packer/${((user_role === "admin" || userHasMultipleClients) ? currentClientId + "/" : "")}reports/picker-performance`,
                label: {
                    old: "Picker Performance Reports",
                    new: "Picker Performance"
                },
                icon: {
                    old: "fas fa-tachometer-alt"
                }
            },
            "FloorsLink": {
                appDomain: 'beta',
                uri: `/p/floors/all`,
                uriRegex: [
                    /\/p\/floors\/(.*)/g,
                ],
                label: {
                    old: "Floors",
                    new: "Product Floor sequences"
                },
                icon: {
                    old: "fab fa-product-hunt",
                    new: "fa-regular fa-arrow-up-small-big"
                }
            },
            "PackerLogsLink": {
                appDomain: 'beta',
                uri: `/p/logs/all`,
                uriRegex: [
                    /\/p\/logs\/(.*)/g,
                ],
                label: {
                    old: "Packer Logs",
                },
                icon: {
                    old: "fab fa-product-hunt"
                }
            },
            "AppVersionsLink": {
                appDomain: 'beta',
                uri: `/dev/app-versions/all`,
                uriRegex: [
                    /\/dev\/app-versions\/(.*)/g,
                ],
                showBetaBadge: {
                    old: true,
                    new: true
                },
                label: {
                    old: "App Versions",
                },
                icon: {
                    old: "fa-solid fa-dial"
                }
            },
            "RecurringJobsLink": {
                appDomain: 'beta',
                uri: `/dev/recurring-jobs/all`,
                uriRegex: [
                    /\/dev\/recurring-jobs\/(.*)/g,
                ],
                showBetaBadge: {
                    old: false,
                    new: false
                },
                label: {
                    old: "Recurring Jobs",
                },
                icon: {
                    old: "fa-solid fa-stopwatch"
                }
            },
            "TwilioTasksLink": {
                appDomain: 'beta',
                uri: `/dev/twilio-tasks/all`,
                uriRegex: [
                    /\/dev\/twilio-tasks\/(.*)/g,
                ],
                showBetaBadge: {
                    old: false,
                    new: false
                },
                label: {
                    old: "Twilio Tasks",
                },
                icon: {
                    old: "fa-solid fa-list-check"
                }
            },
            "ProductManagementLink": {
                uri: `/packer/products`,
                label: {
                    old: "Product Management",
                    new: "Packer Products"
                },
                icon: {
                    old: "fab fa-product-hunt"
                },
                tooltip: {
                    old: "'Product Management' are changing to 'Packer Products'",
                    new: "'Packer Products' was titled 'Product Management'",
                }
            },
            "ProductsimportLink": {
                uri: `/packer/products/upload-redirect`,
                label: {
                    old: "Products Import",
                },
                icon: {
                    old: "fab fa-product-hunt"
                }
            },
            "OrderBoxItemStatusesLink": {
                uri: `/packer/reports/track-order-status`,
                label: {
                    old: "Order/Box/Item statuses",
                    new: "Order Status"
                },
                icon: {
                    old: "fa fa-calendar-alt",
                    new: "fa fa-calendar-days"
                }
            },
            "UserManagementLink": {
                appDomain: 'beta',
                uri: `/u/users/all`,
                uriRegex: [
                    /\/u\/users\/(.*)/g,
                ],
                label: {
                    old: "User Management",
                    new: "User Management"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-users"
                }
            },
            "BetaContractorsLink": {
                appDomain: 'beta',
                uri: `/ctr/contractors/all`,
                uriRegex: [
                    /\/ctr\/contractors\/(.*)/g,
                ],
                label: {
                    old: "Contractors",
                },
                icon: {
                    old: "fas fa-truck"
                },
                showBetaBadge: {
                    old: true,
                    new: false
                }
            },
            "MyAccountLink": {
                appDomain: 'beta',
                uri: `/u/users/self`,
                uriRegex: [
                    /\/u\/users\/self/g,
                ],
                label: {
                    old: "My Account",
                    new: "My Account"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-user-gear"
                }
            },
            "RunSummaryLink" : {
                appDomain: 'beta',
                uri: `/run/r/country/1/${currentDate}`,
                uriRegex: [
                    // /\/run\/r\/country\/1\/(.*)/g,
                    /\/run\/r\/country\/(.*)\/(.*)\/rtw\/(.*)\/rg\/(.*)\/rz\/(.*)\/z\/(.*)\/c\/(.*)\/t\/(.*)/g,
                    /\/run\/r\/country\/(.*)\/(.*)\/rtw\/(.*)\/rg\/(.*)\/rz\/(.*)\/z\/(.*)\/c\/(.*)\/run\/(.*)/g
                ],
                label: {
                    new: "Run Summary",
                },
                icon: {
                    new: "fas fa-truck-bolt"
                },
                showBetaBadge: {
                    old: true,
                    new: true
                }
            },
            "RunsLink": {
                appDomain: 'beta',
                uri: `/run/runs/all`,
                uriRegex: [
                    /\/run\/runs\/(.*)/g,
                ],
                label: {
                    old: "Manage Runs",
                    new: "Manage Runs"
                },
                icon: {
                    old: "fas fa-person-running",
                    new: "fas fa-person-running"
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },
            "RunRulesLink": {
                appDomain: 'beta',
                uri: `/run/run-rules/all`,
                uriRegex: [
                    /\/run\/run-rules\/(.*)/g,
                ],
                label: {
                    old: "Manage Run Rules",
                    new: "Manage Run Rules"
                },
                icon: {
                    old: "fas fa-person-running",
                    new: "fas fa-person-running"
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },
            "LoadoutScreenLink" : {
                appDomain: 'beta',
                uri: `/run/loadout`,
                uriRegex: [
                    /\/run\/loadout/g,
                    /\/run\/loadoutN/g,
                ],
                label: {
                    new: "Loadout Dashboard",
                },
                icon: {
                    new: "fas fa-table-columns"
                },
                showBetaBadge: {
                    old: true,
                    new: true
                }
            },
            "PostSortationBoxValidationLink" : {
                appDomain: 'beta',
                uri: `/run/cleanup`,
                uriRegex: [
                    /\/run\/cleanup/g,
                ],
                label: {
                    new: "Post Sortation Box Validation",
                },
                icon: {
                    new: "fas fa-box-check"
                },
                showBetaBadge: {
                    old: true,
                    new: true
                }
            },
            "BoxLoadCheckLink" : {
                appDomain: 'beta',
                uri: `/d/box-load-check`,
                uriRegex: [
                    /\/d\/box-load-check/g,
                ],
                label: {
                    new: "Box Load Check",
                },
                icon: {
                    new: "fas fa-box-check"
                },
                showBetaBadge: {
                    old: true,
                    new: true
                }
            },
            "PrimaryConnectLink" : {
                appDomain: 'beta',
                uri: `/d/primary-connect/${currentDate}/rg/all/rz/all/z/all/c/all/status/all`,
                uriRegex: [
                    /\/d\/primary-connect\/(.*)\/rg\/(.*)\/rz\/(.*)\/z\/(.*)\/c\/(.*)\/status\/(.*)/g,
                ],
                label: {
                    new: "Primary Connect",
                },
                icon: {
                    new: "fas fa-box-check"
                },
                showBetaBadge: {
                    old: true,
                    new: true
                }
            },
            "RetailDeliveryBookingsLink" : {
                appDomain: 'beta',
                uri: `/d/retail-delivery-bookings/ewAiAHMAZQBsAGUAYwB0AGUAZABDAGwAaQBlAG4AdABzACIAOgBbADUAMgA2AF0ALAAiAGMAbABpAGUAbgB0AEkAZABzACIAOgAiADUAMgA2ACIAfQA`,
                uriRegex: [
                    /\/d\/retail-delivery-bookings\/(.*)/g,
                ],
                label: {
                    new: "Retail Delivery Bookings",
                },
                icon: {
                    new: "fas fa-box-check"
                },
                showBetaBadge: {
                    old: true,
                    new: true
                }
            },
            'FRImportRatesLink': {
                appDomain: 'beta',
                uri: `/b/client-rate-upload`,
                uriRegex: [
                    /\/b\/client-rate-upload/g,
                ],
                label: {
                    new: 'Import Rates'
                },
                icon: {
                    new: 'fas fa-file-import'
                }
            },
            'FRRateMasterLink': {
                appDomain: 'beta',
                uri: `/b/client-rates/all`,
                uriRegex: [
                    /\/b\/client-rates\/(.*)/g,
                ],
                label: {
                    new: 'Rate Master'
                },
                icon: {
                    new: 'fas fa-file-invoice-dollar'
                }
            },
            'FRRevenueReportLink': {
                appDomain: 'beta',
                uri: `/b/revenue`,
                uriRegex: [
                    /\/b\/revenue/g,
                ],
                label: {
                    new: 'Revenue Report'
                },
                icon: {
                    new: 'fas fa-sack-dollar'
                }
            },
            'FRRevenueByMetricReportLink': {
                appDomain: 'beta',
                uri: `/b/revenue-by-metric`,
                uriRegex: [
                    /\/b\/revenue-by-metric/g,
                ],
                label: {
                    new: 'Revenue Metric Report'
                },
                icon: {
                    new: 'fas fa-sack-dollar'
                }
            },
            'FRRatesByClientLink': {
                appDomain: 'beta',
                uri: `/b/client-rates-by-zone`,
                uriRegex: [
                    /\/b\/client-rates-by-zone/g,
                ],
                label: {
                    new: 'Rates by Client'
                },
                icon: {
                    new: 'fas fa-filter-circle-dollar'
                }
            },
            'FRPayRatesLink': {
                uri: `/ct/pay-rates`,
                appDomain: 'legacy',
                label: {
                    new: 'Pay Rates'
                },
                icon: {
                    new: 'fas fa-hand-holding-dollar'
                }
            },
            'FRRoutingGroupWeeklyOverhead': {
                appDomain: 'beta',
                uri: `/b/rg-weekly-cost`,
                uriRegex: [
                    /\/b\/rg-weekly-cost/g,
                ],
                label: {
                    new: 'RG Weekly Overhead'
                },
                icon: {
                    new: 'fas fa-filter-circle-dollar'
                }
            },
            'FRDriverPayReportLink': {
                appDomain: 'beta',
                uri: `/b/driver-pay/all`,
                uriRegex: [
                    /\/b\/driver-pay/g,
                ],
                label: {
                    new: 'Driver Pay Report'
                },
                icon: {
                    new: 'fas fa-sack-dollar'
                }
            },
            'FRFuelSurcharge': {
                appDomain: 'beta',
                uri: `/b/rg-fuel-surcharges`,
                uriRegex: [
                    /\/b\/rg-fuel-surcharges/g,
                ],
                label: {
                    new: 'Fuel Surcharges'
                },
                icon: {
                    new: 'fas fa-sack-dollar'
                }
            },
            'FRClientInvoicingSummaryLink': {
                appDomain: 'beta',
                uri: `/b/client-invoicing`,
                uriRegex: [
                    /\/b\/client-invoicing/g,
                ],
                label: {
                    new: 'Client Invoicing Summary'
                },
                icon: {
                    new: 'fas fa-sack-dollar'
                }
            },
            'FRClientInvoicingItemisedLink': {
                appDomain: 'beta',
                uri: `/b/client-invoicing-itemised/all`,
                uriRegex: [
                    /\/b\/client-invoicing-itemised/g,
                ],
                label: {
                    new: 'Client Invoicing Itemised'
                },
                icon: {
                    new: 'fas fa-sack-dollar'
                }
            },

            "LCRCarrierLink": {
                appDomain: 'beta',
                uri: `/carrier-routing/carrier/all`,
                uriRegex: [
                    /\/carrier-routing\/carrier\/(.*)/g,
                ],
                label: {
                    new: "Carriers"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-repeat"
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },
            "LCRCarrierFuelSurchargesLink": {
                appDomain: 'beta',
                uri: `/carrier-routing/fuel-surcharges`,
                uriRegex: [
                    /\/carrier-routing\/fuel-surcharges/g,
                ],
                label: {
                    new: "Carrier Fuel Surcharges"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-repeat"
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },
            "LCRCarrierLineHaulChargesLink": {
                appDomain: 'beta',
                uri: `/carrier-routing/line-haul`,
                uriRegex: [
                    /\/carrier-routing\/line-haul/g,
                ],
                label: {
                    new: "Line Haul Fees"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-repeat"
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },
            "LCRCarrierFeesLink": {
                appDomain: 'beta',
                uri: `/carrier-routing/fee`,
                uriRegex: [
                    /\/carrier-routing\/fee/g,
                ],
                label: {
                    new: "Last Mile/Tech Fees"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-repeat"
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },
            "LCRCarrierZoneLink": {
                appDomain: 'beta',
                uri: `/carrier-routing/client-zone`,
                uriRegex: [
                    /\/carrier-routing\/client-zone/g,
                ],
                label: {
                    new: "Client Carriers"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-repeat"
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },
            "LCRClientRateLink": {
                appDomain: 'beta',
                uri: `/carrier-routing/client-rate/all`,
                uriRegex: [
                    /\/carrier-routing\/client-rate/g,
                ],
                label: {
                    new: "Ambient Client Rates"
                },
                icon: {
                    old: "fas fa-cog",
                    new: "fas fa-repeat"
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },
            "LCRCourierReportDeliveryListLink": {
                appDomain: 'beta',
                uri: `/carrier-routing/reporting/delivery-list`,
                uriRegex: [
                    /\/carrier-routing\/reporting\/delivery-list/g,
                ],
                label: {
                    new: "Courier Report"
                },
                icon: {
                    old: "fas fa-file-contract",
                    new: "fas fa-file-contract"
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },
            "LCRCourierReportClientDeliveryListLink": {
                appDomain: 'beta',
                uri: `/carrier-routing/reporting/client-delivery-list`,
                uriRegex: [
                    /\/carrier-routing\/reporting\/client-delivery-list/g,
                ],
                label: {
                    new: "Client Courier Report"
                },
                icon: {
                    old: "fas fa-file-contract",
                    new: "fas fa-file-contract"
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },
            "LCRAmbientBillingLink": {
                appDomain: 'beta',
                uri: `/carrier-routing/reporting/ambient-billing`,
                uriRegex: [
                    /\/carrier-routing\/reporting\/ambient-billing/g,
                ],
                label: {
                    new: "Ambient Billing"
                },
                icon: {
                    old: "fas fa-file-contract",
                    new: "fas fa-file-contract"
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },
            "LCRCourierReportSummaryLink": {
                appDomain: 'beta',
                uri: `/carrier-routing/reporting/summary`,
                uriRegex: [
                    /\/carrier-routing\/reporting\/summary/g,
                ],
                label: {
                    new: "Courier Summary"
                },
                icon: {
                    old: "fas fa-file-contract",
                    new: "fas fa-file-contract"
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },
            "LCRPickingLink": {
                appDomain: 'beta',
                uri: `/carrier-routing/reporting/picking`,
                uriRegex: [
                    /\/carrier-routing\/reporting\/picking/g,
                ],
                label: {
                    new: "Ambient Picking Report"
                },
                icon: {
                    old: "fas fa-file-contract",
                    new: "fas fa-file-contract"
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },
            "BetaAssetTrackerLink": {
                appDomain: 'beta',
                uri: `/mhe/equipment/map/all`,
                uriRegex: [
                    /\/mhe\/equipment\/map\/(.*)/g,
                ],
                label: {
                    new: "Asset Tracker"
                },
                icon: {
                    old: "fa-sharp fa-solid fa-dolly",
                    new: "fa-sharp fa-solid fa-dolly"
                },
                showBetaBadge: {
                    old: true,
                    new: true
                }
            },
            "SyncIssueLink": {
                appDomain: 'beta',
                uri: `/d/sync-issues/all`,
                uriRegex: [
                    /\/d\/sync-issues\/(.*)/g,
                ],
                label: {
                    old: "Sync Issues",
                    new: "Sync Issues"
                },
                icon: {
                    old: "fas fa-triangle-exclamation",
                    new: "fas fa-triangle-exclamation",
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },

            "ContractorPaySummaryLink": {
                appDomain: 'beta',
                uri: `/ct/reports/pay-summary/all`,
                uriRegex: [
                    /\/ct\/reports\/pay-summary\/(.*)/g,
                ],
                label: {
                    old: "Pay Summary",
                    new: "Pay Summary"
                },
                icon: {
                    old: "fas fa-dollar",
                    new: "fas fa-dollar"
                },
                showBetaBadge: {
                    old: false,
                    new: false
                }
            },
        }
        this.titleMap = [
            {
                path: /\/run\/r\/dropN\/(.*)/g,
                title:'Drop Detail' // Delivery Detail
            },
            {
                path: /\/run\/r\/delivery\/(.*)/g,
                title:'Delivery Detail' // Delivery Detail
            }
        ]
    }

    getLinkAppDomain = (type) => {
        return this.links[type].appDomain ?? 'legacy';
    }
    getLinkURL = (type) => {
        return this.links[type].uri ?? {uri: "/no-access"};
    }
    getLabel = (type, variant = "old") => {
        let label = null
        try{
            label = this.links[type]['label'][variant] ?? this.links[type]['label']['old'];
        }
        catch(e){
            label = null;
        }

        return label;
    }
    getIcon = (type, variant = "old") => {
        let icon = null;
        try{
            icon = this.links[type]['icon'][variant] ?? this.links[type]['icon']['old'];
        }
        catch(e){
            icon = null;
        }
        return icon;
    }
    getTooltip = (type, variant = "old") => {
        let tooltip = null;
        try{
            tooltip = this.links[type]['tooltip'][variant];
        }
        catch(e){
            tooltip = null;
        }

        const tooltipStyle = this.links[type]['tooltipColor'] ? {color: this.links[type]['tooltipColor']} : null;

        return tooltip ? <i style={tooltipStyle} className="fas fa-info-circle menu-tooltip" title={tooltip}></i> : '';
    }
    getBetaBadge = (type, useNewLabel) => {
        let version = useNewLabel ? "new" : "old";
        let showBetaBadge = false;
        try{
            showBetaBadge = this.links[type]['showBetaBadge'][version]
        }
        catch(e){
            showBetaBadge = false;
        }
        return showBetaBadge ? <span className="badge mr-2">Beta</span> : '';
    }
    generateLink = (type, notifications, useNewLabel = false, useNewIcon = false) => {
        return (
            <NavLink key={uuid()} to={this.getLinkURL(type)} type={this.getLinkAppDomain(type)}>
                <button data-url="dashboard" data-notifications={notifications ?? 0} className="nav-button">
                    {useNewIcon ? (
                        <>{this.getIcon(type, 'new') && <i className={this.getIcon(type, 'new')}></i>}</>
                    ):(
                        <>{this.getIcon(type) && <i className={this.getIcon(type)}></i>}</>
                    )}
                    {this.getLinkAppDomain(type) === 'beta' && this.getBetaBadge(type, useNewLabel)}
                    {useNewLabel ? (
                        <span>{this.getLabel(type, "new")} {this.getTooltip(type,'new')}</span>
                    ):(
                        <span>{this.getLabel(type)} {this.getTooltip(type)}</span>
                    )}
                </button>
            </NavLink>
        )
    }
    getUriTitleMap = () => {
        let uriTitleMap = [];
        Object.keys(this.links).forEach(key => {
            if(this.links[key].uriRegex){
                this.links[key].uriRegex.forEach(regex => {
                    uriTitleMap.push({
                        path: regex,
                        title: this.getLabel(key, "new") ?? this.getLabel(key)
                    })
                })
            }
        })
        return [...this.titleMap, ...uriTitleMap];
    }
}

export default SiteNavLinkBuilder;
