const theme = {
    colors: {
        headerLight: '#00adee',
        headerDark: '#002bbb',
        success: '#1BC5BD',
        primary: '#187DE4',
        primaryLight: '#187DE423',
        secondary: '#E4E6EF',
        info: '#8950FC',
        danger: '#F64E60',
        warning: '#FFA800'
    },
    colorBgClass: {
        success: 'nv-success-bg',
        primary: 'nv-primary-bg',
        secondary: 'nv-secondary-bg',
        info: 'nv-info-bg',
        danger: 'nv-danger-bg',
        warning: 'nv-warning-bg'
    },
    colorClass: {
        success: 'nv-success',
        primary: 'nv-primary',
        secondary: 'nv-secondary',
        info: 'nv-info',
        danger: 'nv-danger',
        warning: 'nv-warning'
    }
};

export default theme;