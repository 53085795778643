import './whyr';
import * as React from 'react';
import { render, hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import Loadable from 'react-loadable';
import { HashRouter } from 'react-router-dom';
import createStore from './store';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './app/App';

// Create a store and get back itself and its history object
const { store } = createStore();

// Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
// Let's also let React Frontload explicitly know we're not rendering on the server here
const Application = (
    <Provider store={store}>
        <HashRouter>
            <App />
        </HashRouter>
    </Provider>
);

const root = document.getElementById('app-root');

if (root.hasChildNodes() === true) {
    Loadable.preloadReady().then(() => {
        hydrate(Application, root);
    });
} else {
    render(Application, root);
}