export default function patchActionCreators(resourceName, actionTypes, api) {
    let actionCreators = {

        // TODO: patchedData should be what comes back from the API once we get a chance to return the patched entity / patched fields
        patch: function (id, data, auth, queryParams, patchedData = {}) {
            return function (dispatch) {
                dispatch(actionCreators.request(id));

                return api.patch(id, data, auth, queryParams)
                    .then(
                        response => {
                            if (response.errors) {
                                dispatch(actionCreators.failure(id, response.errors));
                            } else {
                                dispatch(actionCreators.success(id, response, patchedData));
                            }
                        },
                        exception => dispatch(actionCreators.failure(id, exception))
                    ).then(
                        response => dispatch(actionCreators.complete(id)),
                        exception => dispatch(actionCreators.complete(id))
                    );
            }
        },

        request: function (id) {
            let name = resourceName + '_PATCH_REQUEST';

            return {
                type: actionTypes[name],
                meta: {
                    id
                }
            }
        },

        success: function (id, data, patchedData) {
            let name = resourceName + '_PATCH_SUCCESS';

            console.log("PATCHED SUCCESS", name)

            return {
                data: data,
                itemData: patchedData,
                type: actionTypes[name],
                meta: {
                    id
                }
            }
        },

        complete: function (id) {
            let name = resourceName + '_PATCH_COMPLETE';

            return {
                data: {},
                type: actionTypes[name],
                meta: {
                    id
                }
            }
        },

        failure: function (id, errors) {
            let name = resourceName + '_PATCH_FAILURE';

            return {
                errors: errors,
                type: actionTypes[name],
                meta: {
                    id
                }
            }
        }
    };

    return {
        patch: actionCreators.patch
    };
}