import _uniqBy from 'lib/uniqBy';

export default function reducers(resourceName, options) {

    return {
        [resourceName + '_LIST_REQUEST']: (state, payload) => {
            return Object.assign({}, state, {
                isRequesting: true,
                hasRequested: false,
                cancelToken: payload.cancelToken
            });
        },

        [resourceName + '_LIST_CANCEL']: (state, payload) => {
            return Object.assign({}, state, {
                isCancelled: true,
                isRequesting: false,
                cancelToken: null
            });
        },

        [resourceName + '_LIST_FAILURE']: (state, payload) => {
            return Object.assign({}, state, {
                isRequesting: false,
                items: [],
                meta: {},
                errors: payload.errors,
                hasRequested: true,
                cancelToken: null
            });
        },

        [resourceName + '_LIST_SUCCESS']: (state, payload) => {
            let items = [];

            
            if (payload.mergeKey === true) {
                items = state.items === undefined ? payload.data : state.items.concat(payload.data);
            } else if (payload.mergeKey === false) {
                items = payload.data;
            } else if (payload.mergeKey !== undefined) {
                items = state.items === undefined ? payload.data : state.items.concat(payload.data);
                items = _uniqBy(items, payload.mergeKey);
            } else {
                items = payload.data;
            }

            return Object.assign({}, state, {
                isRequesting: false,
                isCancelled: false,
                items: items,
                meta: payload.meta,
                hasRequested: true,
                cancelToken: null
            });
        },

        [resourceName + '_LIST_COMPLETE']: (state, payload) => {
            return Object.assign({}, state, {
                isRequesting: false,
                isCancelled: false,
                cancelToken: null
            });
        },

        [resourceName + '_LIST_CLEAR']: (state, payload) => {
            return Object.assign({}, state, {
                items: [],
                meta: {}
            });
        },

        [resourceName + '_LIST_SETITEM']: (state, payload) => {
            return Object.assign({}, state, {
                item: payload.data.item
            });
        }
    }
}