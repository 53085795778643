import { createReducer } from 'lib/createReducer';
import {
    IS_EDITING_FORM,
    IS_EDIT_PAGE,
    UPDATE_DA_FAILURE,
    UPDATE_DA_SUCCESS,
    UPDATE_DA_REQUEST
} from '../constants/DashboardActivity';

const initialState = {
    activity: null,
    editFormPath: false,
    resourceName: '',
    resourceId: null,
    editPagePath: false
};

export default createReducer(initialState, {

    [UPDATE_DA_REQUEST]: (state, payload) => {
        return Object.assign({}, state, {

        });
    },

    [UPDATE_DA_FAILURE]: (state, data) => {
        return Object.assign({}, state, {

        });
    },

    [UPDATE_DA_SUCCESS]: (state, data) => {
        return Object.assign({}, state, {
            'activity': data.payload.data
        });
    },

    [IS_EDITING_FORM]: (state, data) => {
        return Object.assign({}, state, {
            'editFormPath': data.payload.editFormPath
        });
    },

    [IS_EDIT_PAGE]: (state, data) => {
        return Object.assign({}, state, {
            'resourceName': data.payload.resourceName,
            'resourceId': data.payload.resourceId,
            'editPagePath': data.payload.editPagePath
        });
    }
});