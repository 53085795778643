export default function getActionCreators(resourceName, actionTypes, api, options) {
    let actionCreators = {

        getSingle: function (auth, params, clear = false) {
            return function (dispatch) {
                dispatch(actionCreators.getRequest(clear));

                return api.query(auth, params)
                    .then(
                        response => dispatch(response.errors ? actionCreators.getFailure(response.errors) : actionCreators.getSuccess(response.data)),
                        exception => dispatch(actionCreators.getFailure(exception))
                    ).then(
                        response => dispatch(actionCreators.getComplete()),
                        exception => dispatch(actionCreators.getComplete())
                    );
            }
        },

        getFirst: function (auth, params, clear = false) {
            return function (dispatch) {
                dispatch(actionCreators.getRequest(clear));

                return api.query(auth, params)
                    .then(
                        response => dispatch(response.errors ? actionCreators.getFailure(response.errors) : actionCreators.getSuccess(response.data[0])),
                        exception => dispatch(actionCreators.getFailure(exception))
                    ).then(
                        response => dispatch(actionCreators.getComplete()),
                        exception => dispatch(actionCreators.getComplete())
                    );
            }
        },

        get: function (id, auth, params, clear = false) {

            if (id == null) {
                return function (dispatch) {
                    dispatch(actionCreators.getRequest(clear));

                    return api.query(auth, params)
                        .then(
                            response => dispatch(response.errors ? actionCreators.getFailure(response.errors) : actionCreators.getSuccess(response)),
                            exception => dispatch(actionCreators.getFailure(exception))
                        ).then(
                            response => {
                                dispatch(actionCreators.getComplete());
                                return response;
                            },
                            exception => dispatch(actionCreators.getComplete())
                        );
                }
            }

            return function (dispatch) {
                dispatch(actionCreators.getRequest(clear));

                return api.get(id, auth, params)
                    .then(
                        response => dispatch(response.errors ? actionCreators.getFailure(response.errors) : actionCreators.getSuccess(response)),
                        exception => dispatch(actionCreators.getFailure(exception))
                    ).then(
                        response => {
                            dispatch(actionCreators.getComplete());
                            return response;
                        },
                        exception => dispatch(actionCreators.getComplete())
                    );
            }
        },

        getRequest: function (clear) {
            let name = resourceName + '_GET_REQUEST';

            return {
                type: actionTypes[name],
                data: clear
            }
        },

        getSuccess: function (data) {
            let name = resourceName + '_GET_SUCCESS';

            return {
                data: options.hasIndividualDataWrapper ? data.data : data,
                type: actionTypes[name]
            }
        },

        getComplete: function () {
            let name = resourceName + '_GET_COMPLETE';

            return {
                data: {},
                type: actionTypes[name]
            }
        },

        getFailure: function (error, data) {
            let name = resourceName + '_GET_FAILURE';

            return {
                data: data,
                error: error,
                errors: error,
                type: actionTypes[name]
            }
        },

        clear: function (error, data) {
            let name = resourceName + '_GET_CLEAR';

            return {
                data: {},
                type: actionTypes[name]
            }
        }
    };

    return {
        getFirst: actionCreators.getFirst,
        getSingle: actionCreators.getSingle,
        get: actionCreators.get,
        getClear: actionCreators.clear
    };
}