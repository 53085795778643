import * as React from 'react';
import MaskedInput from 'react-text-mask';
import { Controller } from 'react-hook-form';

/* @jsxImportSource @emotion/react */  import { css, jsx } from '@emotion/react'; /* eslint-disable-line */

const baseComponentCss = css`

    input {
        display: block;
        width: 100%;
        height: calc(1.5em + 1.3rem + 2px);
        padding: .65rem 1rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #3f4254;
        background-color: #f3f6f9;
        background-clip: padding-box;
        border: 1px solid #f3f6f9;
        border-radius: .42rem;
        box-shadow: none;
        transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;

        &:focus {
            border: none;
        }
        &:read-only {
            color: #ccc;
        }
    }


`;

const BaseComponent = React.forwardRef((props, ref) => {
    return <div css={baseComponentCss} className={props.inputClassName}>
        {props.mask ?
            <Controller as={MaskedInput} control={props.control} type={props.type ? props.type : 'input'} name={props.name} placeholder={props.placeholder} mask={props.mask} onChange={props.onChange} /> :
            <input type={props.type ? props.type : 'input'} readOnly={!!props.readOnly} name={props.name} ref={ref} placeholder={props.placeholder} onChange={props.onChange} />
        }
    </div>
});

export default BaseComponent;