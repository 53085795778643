export default function createActionCreators(resourceName, actionTypes, api) {
    let actionCreators = {

        create: function (data, auth, queryParams) {
            return function (dispatch) {
                dispatch(actionCreators.createRequest());

                return api.create(data, auth, queryParams)
                    .then(
                        response => dispatch(response.errors ? actionCreators.createFailure(response.errors, response.data) : actionCreators.createSuccess(response)),
                        exception => dispatch(actionCreators.createFailure(exception))
                    ).then(
                        response => dispatch(actionCreators.createComplete(response)),
                        exception => dispatch(actionCreators.createComplete())
                    );
            }
        },

        createRequest: function () {
            let name = resourceName + '_CREATE_REQUEST';

            return {
                type: actionTypes[name]
            }
        },

        createSuccess: function (data) {
            let name = resourceName + '_CREATE_SUCCESS';

            return {
                data: data,
                type: actionTypes[name]
            }
        },

        createComplete: function (data) {
            let name = resourceName + '_CREATE_COMPLETE';

            return {
                data: data?.data,
                errors: data?.errors,
                type: actionTypes[name]
            }
        },

        createFailure: function (errors, additional) {
            let name = resourceName + '_CREATE_FAILURE';

            return {
                errors: errors,
                additional: additional,
                type: actionTypes[name]
            }
        }
    };

    return {
        create: actionCreators.create
    };
}