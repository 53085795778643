import {
	LOGOUT_USER_REQUEST,
	LOGOUT_USER_SUCCESS,
	LOGOUT_USER_FAILURE
} from '../constants/Authentication';

import Cookies from 'js-cookie';
import config from 'app/config';

export function logoutUser(token, history) {
	return function (dispatch) {
		dispatch(logoutUserRequest());
		Cookies.remove(config.cookieName, {
			path: '/',
			cookieDomain: config.cookieDomain
		});
		dispatch(logoutUserSuccess());
		history.push({ pathname: "/" });
	}
}

export function logoutUserRequest() {
	return {
		type: LOGOUT_USER_REQUEST
	}
}

export function logoutUserSuccess() {
	return {
		type: LOGOUT_USER_SUCCESS
	}
}

export function logoutUserFailure(error) {
	return {
		type: LOGOUT_USER_FAILURE,
		payload: {
			status: error.response.status,
			logoutDisplayMessage: error.response.statusText
		}
	}
}
