import * as React from 'react';

/* @jsxImportSource @emotion/react */  import { css, jsx } from '@emotion/react'; /* eslint-disable-line */

const baseComponentCss = css`

    display: flex;
    align-items: center;
    gap: 10px;
    
    label {
        margin-bottom: 0;
    }

    .switch {
        height: 14px; 

        &.switch-x input:checked ~ span:before {
            background-color: #1C64F2;
        }

        &.switch-x input:checked ~ span:after {
            margin-left: 16px;
            opacity: 1;
            color: #ffffff;
            background-color: #EBEDF3;
            content: " ";
            line-height: 0;
        }
        
        input:empty {
            margin-left: -999px;
            height: 0;
            width: 0;
            overflow: hidden;
            position: absolute;
            opacity: 0;
            padding: 0;
        }

        input:empty ~ span {
            margin: 0;
            height: 14px;
            width: 30px;
            border-radius: 15px;
            display: inline-block;
            position: relative;
            text-indent: 0;
            cursor: pointer;
            user-select: none;
        }

        input:empty ~ span:before {
            background-color: #EBEDF3;
        }

        input:empty ~ span:before, input:empty ~ span:after {
            width: 30px;
            border-radius: 15px;
            position: absolute;
            display: flex;
            top: 0;
            bottom: 0;
            left: 0;
            content: ' ';
            transition: all 200ms ease-in;
        }

        input:empty ~ span:after {
            background-color: #ffffff;
            opacity: 0.7;
            height: 12px;
            width: 12px;
            top: 1px;
            bottom: 1px;
            margin-left: 2px;
            font-size: 0.65em;
            vertical-align: middle;
        }

        input[disabled] ~ span {
            opacity: 0.5;
        }

        + label {
            margin-bottom: 0;
        }
    }

`;

const Switch = React.forwardRef(({ name, onChange, onBlur, value, isDisabled, label, className }, ref) => {
	return <div css={baseComponentCss} className={className}>
		<label className="switch switch-x">
			<input type="checkbox" checked={value == 1} name={name} id={name} ref={ref} onChange={onChange} onBlur={onBlur} disabled={isDisabled}  />
			<span />
		</label>
		{label && <label htmlFor={name}>{label}</label>}
	</div>
});

export default Switch;