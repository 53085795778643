import isFunction from 'lib/isFunction';
import axios from 'axios';

export const checkHttpStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
        return response
    } else {
        if (response.data.errors) {
            return response;
        }
        let error = new Error(response.statusText)
        error.response = response
        throw error
    }
}

export const parseJSON = (response) => {
    return response.data;
}

export const parseBlob = (response) => {

    let type = 'application/pdf';

    if (response.data && response.data.type == "text/html") {
        type = "text/html";
    }

    const file = new Blob(
        [response.data],
        {type: type});
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
    window.open(fileURL);
    response.url = fileURL;
    return response;
}

export const standardHeaders = (auth) => {
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    if (auth?.token) {
        headers['Authorization'] = 'Bearer ' + auth.token;
    }

    return headers;
};

export const cancelToken = () => {
    const CancelToken = axios.CancelToken;
    return CancelToken.source();
}

const crudQuery = (parsePath, onReceive) => {
    return (auth, params) => {
        return axios.get(`${parsePath(null, params, auth)}`, {
            params: params,
            headers: standardHeaders(auth),
            validateStatus: function (status) { return status >= 200 && status < 500; }
        }).then(checkHttpStatus).then(parseJSON).then(onReceive)
    };
};

const crudList = (parsePath, onReceive, cancelTokenSource) => {
    return (auth, params, cancelTokenSource) => {
        return axios.get(`${parsePath(null, params, auth)}`, {
            cancelToken: cancelTokenSource ? cancelTokenSource.token : null,
            params: params,
            headers: standardHeaders(auth),
            validateStatus: function (status) { return status >= 200 && status < 500; }
        }).then(checkHttpStatus).then(parseJSON).then(resp => {

            let itemsOR = [];

            resp.data.forEach(x => {
                itemsOR.push(onReceive(x));
            });

            resp.data = itemsOR;

            return resp;
        })
    };
};

const crudGet = (parsePath, onReceive) => {
    return (id, auth, params) => {
        return axios.get(`${parsePath(id, params, auth)}/${id}`, {
            params: params,
            headers: standardHeaders(auth),
            validateStatus: function (status) { return status >= 200 && status < 500; }
        }).then(checkHttpStatus).then(parseJSON).then(onReceive)
    };
};

const crudDelSingle = (parsePath) => {
    return (auth, params) => {
        return axios.delete(`${parsePath(null, params, auth)}`, {
            headers: standardHeaders(auth),
            validateStatus: function (status) { return status >= 200 && status < 500; }
        });
    };
};

const crudDel = (parsePath) => {
    return (id, auth, params) => {
        return axios.delete(`${parsePath(id, params, auth)}/${id}`, {
            params: params,
            headers: standardHeaders(auth),
            validateStatus: function (status) { return status >= 200 && status < 500; }
        });
    };
};

const crudPatch = (parsePath, onSend, onReceive) => {
    return (id, data, auth, params) => {
        return axios.patch(`${parsePath(id, params, auth)}/${id}`, onSend(data), {
            headers: standardHeaders(auth),
            validateStatus: function (status) { return status >= 200 && status < 500; }
        }).then(checkHttpStatus).then(parseJSON).then(onReceive);
    };
};

const crudCreate = (parsePath, onSend, onReceive) => {
    return (data, auth, params) => {
        return axios.post(`${parsePath(null, Object.assign({}, params, data), auth)}`, onSend(data), {
            headers: standardHeaders(auth),
            validateStatus: function (status) { return status >= 200 && status < 500; }
        }).then(checkHttpStatus).then(parseJSON).then(onReceive);
    };
};

const crudUpdate = (parsePath, onSend, onReceive) => {
    return (id, data, auth, params) => {
        if (id === null) {
            return axios.put(`${parsePath(id, Object.assign({}, params, data), auth)}`, onSend(data), {
                headers: standardHeaders(auth),
                validateStatus: function (status) { return status >= 200 && status < 500; }
            }).then(checkHttpStatus).then(parseJSON).then(onReceive);
        }
        return axios.put(`${parsePath(id, Object.assign({}, params, data), auth)}/${id}`, onSend(data), {
            headers: standardHeaders(auth),
            validateStatus: function (status) { return status >= 200 && status < 500; }
        }).then(checkHttpStatus).then(parseJSON).then(onReceive);
    };
};

const crudCancel = (cancelSource) => {
    if (cancelSource !== undefined && cancelSource !== null) {
        cancelSource.cancel('Operation canceled by the user.');
    }
}

const crudUpload = (parsePath, onReceive) => {
    return (id, file, auth, params) => {
        const fd = new FormData();
        fd.append('id', id);
        fd.append('file', file);

        return axios.post(`${parsePath(null, params, auth)}/${id}/file`, fd, {
            headers: {
                'Authorization': 'Bearer ' + auth.token
            },
            validateStatus: function (status) { return status >= 200 && status < 500; }
        }).then(checkHttpStatus);
    };
};

const crudDownload = (parsePath, onSend, onReceive) => {
    return (data, auth, params) => {
        return axios.post(`${parsePath(null, Object.assign({}, params, data), auth)}`, onSend(data), {
            headers: standardHeaders(auth),
            responseType: 'blob',
            validateStatus: function (status) { return status >= 200 && status < 500; }
        }).then(checkHttpStatus).then(parseBlob).then(onReceive);
    };
};

export default function rest(path, onReceive, onSend) {

    const parsePath = (id, params, auth) => {
        let newPath = path;

        if (isFunction(newPath)) {
            newPath = newPath(id, params, auth && auth.token);
        }

        // newPath = config.apiBase + "/" + newPath;

        params !== undefined && Object.keys(params).forEach((v, k) => {
            newPath = newPath.replace('{' + k + '}', v);
        });

        return newPath;
    };


    return {
        get: crudGet(parsePath, onReceive),
        query: crudQuery(parsePath, onReceive),
        list: crudList(parsePath, onReceive),
        patch: crudPatch(parsePath, onSend, onReceive),
        create: crudCreate(parsePath, onSend, onReceive),
        update: crudUpdate(parsePath, onSend, onReceive),
        upload: crudUpload(parsePath, onSend, onReceive),
        del: crudDel(parsePath, onSend, onReceive),
        delSingle: crudDelSingle(parsePath, onSend, onReceive),
        download: crudDownload(parsePath, onSend, onReceive),
        cancel: crudCancel,
        cancelToken: cancelToken
    }
}
