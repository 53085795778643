import * as React from 'react';
import axios from 'axios';
import config from 'app/config';
import { standardHeaders } from 'lib/api/rest';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, info: null };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true, error: error, info: info });

        if (document.location.host.startsWith('localhost')) {
            return;
        }
        
        return axios.post(`${config.baseURI}/nv/system-error`, {
            httpMethod: 'FRONTEND',
            uri: this.props.location?.pathname,
            ipAddress: '',
            request: '',
            response: error.toString(),
            stackTrace: info.componentStack,
            queryString: this.props.location?.search
        }, {
            headers: standardHeaders(this.props.auth),
        });
    }

    render() {
        if (this.state.hasError) {
            console.log("EB", this.state.error);
            
            return <div>
                <h1>Something went wrong.</h1>
                <a href="/">Home</a>
                <pre>{this.state.error.stack}</pre>
            </div>;
        }
        return this.props.children;
    }
}