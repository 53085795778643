import config from 'app/config';

const getNextDelimiter = (link) => {
    if (link.includes('?')) {
        return '&';
    }
    return '?';
}

const getExpandedMenu = () => {
    let expandedMenu = window?.localStorage?.getItem('expandedMenu');

    let newMenuEnabled = false;
    let expandedSections = '';
    let isDirty = false;

    if (expandedMenu) {
        let expandedMenuObj = JSON.parse(expandedMenu);
        newMenuEnabled = expandedMenuObj.enabled;
        expandedSections = expandedMenuObj.indexes.join(',');
        isDirty = expandedMenuObj.isDirty;
    }

    if (newMenuEnabled) {
        return expandedSections;
    }

    return null;
}

const getSidenavScrollPosition = () => {
    let expandedMenu = window?.localStorage?.getItem('expandedMenu');
    let scroll = 0;
    
    if (expandedMenu) {
        let expandedMenuObj = JSON.parse(expandedMenu);
        scroll = expandedMenuObj.scroll;
    }

    if (scroll != 0){
        return scroll;
    }

    return null;
}

const formatCrossLink = (link, token, params) => {

    let finalLink = config.betaURI + '/#' + link;
    let expandedMenuSections = getExpandedMenu();
    let sideNavScrollPosition = getSidenavScrollPosition();

    if (token) {
        finalLink += getNextDelimiter(finalLink) + 'authToken='+ token;
    }

    if (params) {
        finalLink += getNextDelimiter(finalLink) + params;
    }

    if (expandedMenuSections) {
        finalLink += getNextDelimiter(finalLink) + 'expandedMenuSections=' + expandedMenuSections;
    }

    if (sideNavScrollPosition) {
        finalLink += getNextDelimiter(finalLink) + 'sideNavScrollPosition=' + sideNavScrollPosition;
    }

    return finalLink;
}

export default formatCrossLink;