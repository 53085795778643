// The basics
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
/* @jsxImportSource @emotion/react */  import { css, jsx } from '@emotion/react'; /* eslint-disable-line */
import Routes from './modules/Routes';
import { withRouter } from 'react-router';
import SiteNavigation from './components/SiteNavigation';
import SiteHeader from './components/siteHeader/Header';
import TagManager from 'react-gtm-module'

import Alert from 'app/nv/components/Alert';

import * as verifyTokenAC from './modules/authentication/action/VerifyToken';
import * as searchParamsAC from './modules/authentication/action/SearchParams';
import * as dashboardActivityAC from './modules/authentication/action/DashboardActivity';
import * as flexAuthAC from './modules/authentication/action/TwilioFlex';

import AuthCheck from './modules/authentication/component/AuthCheck';
import Loadable from 'react-loadable';
import useExpandedMenu from './common/useExpandedMenu';

import * as loginAC from 'app/modules/authentication/action/Login';

import ErrorBoundary from 'app/components/ErrorBoundary';
import Cookies from 'js-cookie';
import { Switch, Route, Redirect } from 'react-router-dom';

import { isPageVisible } from 'app/hooks/usePageVisibility';

import config from 'app/config';
import {loginUserWithFlexIdentity} from './modules/authentication/action/TwilioFlex';

const Authentication = Loadable({
    loader: () => import(/* webpackChunkName: "authentication" */ './modules/authentication/Routes'),
    loading: () => null,
    modules: ['authentication'],
    webpack: () => [require.resolveWeak('./modules/authentication/Routes')] // Prevents white flash
});

const betaCss = css`
	flex: 1 1 auto;
`;


const scCss = css`
    display: flex;
    height: calc(100vh - 64px);
    overflow: hidden;
`;

const newMainCss = css`
    flex: 1 1 auto;
    overflow-y: auto;
`;

const pageMainCss = css`
    flex: 1 1 auto;
    padding: 20px 20px 0 20px;
    background: #f0f0f0;
    overflow-y: auto;
`;

const publicCss = css`
    width: 100%;
    height: 100%;
`
const tagManagerArgs = {
    gtmId: 'GTM-WVV5DJN',
    dataLayerName: 'PageDataLayer',
}

if (process.env.NODE_ENV === 'production') {
    TagManager.initialize(tagManagerArgs);
}

class App extends React.Component {

    lastStateChange = null;
    lastPage = "";
    editPagePath = "";

    isUnauthorized = () => {
        this.props.vtActions.verifyToken(Cookies.get(config.cookieName));
    }

    componentDidMount() {

        console.log("LEGACY", this.props.location.search)

        this.props.spActions.decodeSearchParams(this.props);
        if (this.props.location.search.indexOf('twilioFlexAuth=') !== -1) {
            var url = new URLSearchParams(this.props.location.search);
            this.props.flexActions.loginUserWithFlexIdentity(url.get('twilioFlexAuth'), url.get('twilioFlexToken'));
        } else {
            this.props.vtActions.verifyToken(Cookies.get(config.cookieName));
        }

		let baseTitle = 'ArcTech';
		if (process.env.NODE_ENV === 'development') {
			baseTitle = 'ArcTech (Dev)';
		}
        document.title = baseTitle

        this.timer = setInterval(() => {
            const editPagePath = this.props.dashboardActivity.editPagePath === this.props.location.pathname ? true : false;

            // Don't update activity if the page isn't active
            if (!isPageVisible()) {
                return;
            }

            if (this.editPagePath !== editPagePath || this.lastPage !== this.props.location.pathname || new Date() - this.lastStateChange > 60000 || (editPagePath && (new Date() - this.lastStateChange > 9000))) {
                this.lastStateChange = new Date();
                this.lastPage = this.props.location.pathname;
                this.editPagePath = editPagePath;

                if (this.props.auth.isAuthenticated) {
                    this.props.daActions.updateDashboardActivity(this.props.auth, this.props.location.pathname, this.props.location.search, this.props.dashboardActivity.editFormPath === this.props.location.pathname ? true : false, editPagePath, this.props.dashboardActivity.resourceName, this.props.dashboardActivity.resourceId, this.isUnauthorized);
                }
            }

        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    componentDidUpdate(prevProps) {

        const currentPath = this.props.location.pathname;

        if (this.props.location.search !== prevProps.location.search) {
            this.props.spActions.decodeSearchParams(this.props);
        }

        if (this.props.auth.isAuthenticated && this.props.searchParams.hasDecoded && currentPath == '/') {
            this.props.history.push({ pathname: '/cd' });
        }

        if (this.props.auth.isAuthenticated && this.props.searchParams.hasDecoded && this.props.searchParams.nextPath) {
            this.props.history.push({ pathname: this.props.searchParams.nextPath });
        } else if (this.props.auth.isAuthenticated && !prevProps.auth.isAuthenticated && currentPath.startsWith('/auth')) {
            this.props.history.push({ pathname: '/cd' });
        }

        if (this.props.searchParams.authToken) {
            const scroll = this.props.searchParams.sideNavScrollPosition ?? 0;

            if (this.props.searchParams.expandedMenuSections){
                const expandedMenuSections = (this.props.searchParams.expandedMenuSections).split(',').map(i => parseInt(i));
                this.props.setExpandedMenuSections({indexes: expandedMenuSections, enabled: true, scroll});
            } else {
                this.props.setExpandedMenuSections({...this.props.expandedMenuSections, enabled: false, scroll});
            }

            if (scroll) {
                window.setSideNavScrollTop = scroll;
            }

            const newSearchParams = {...this.props.searchParams };
            delete newSearchParams.authToken;
            delete newSearchParams.hasDecoded;
            delete newSearchParams.expandedMenuSections;
            delete newSearchParams.sideNavScrollPosition;

            let newSearch = '';
            if (Object.keys(newSearchParams).length > 0) {
                newSearch = new URLSearchParams(newSearchParams).toString()
            }


            this.props.history.replace({
                search: newSearch,
            })

            this.props.loginActions.setUserToken(this.props.searchParams.authToken);

        }

    }

    render() {

        const props = this.props;

        const betaStyle = props.location.pathname.startsWith('/beta');
        const newPageStyle = (props.location.pathname.startsWith('/nv/runs') && !props.location.pathname.startsWith('/nv/runs/events')) || props.location.pathname.startsWith('/nv/delivery-summary') ? true : false;
        const renderSiteHeader = props.location.pathname.startsWith('/special') ? false : true;
        const readerSiteNav = props.location.pathname.startsWith('/special') ? false : true;

        if (!props.auth.isAuthenticated && !props.auth.didAttemptTokenVerification) {
            return <AuthCheck />
        }

        if (!props.auth.isAuthenticated) {
            return <div css={publicCss}>
                <Switch>
                    <Route path="/auth" component={Authentication} />
                    <Redirect to={`/auth/login?nextPath=${props.location.pathname}`} />
                </Switch>
            </div>
        }

        const da = this.props.dashboardActivity.activity;

        const tagManagerArgs = {
            dataLayer: {
                userId: props.auth.user.id,
                newMenu: props.expandedMenuSections?.enabled,
            },
            dataLayerName: 'PageDataLayer'
        }

        if (process.env.NODE_ENV === 'production') {
            TagManager.dataLayer(tagManagerArgs);
        }

        return (
            <div id="app">
                <ErrorBoundary auth={this.props.auth} location={this.props.location}>
                    {renderSiteHeader && <SiteHeader auth={this.props.auth} location={this.props.location} />}
                    <div id="site-container" css={readerSiteNav ? scCss : null}>
                        {readerSiteNav && <SiteNavigation auth={this.props.auth} location={this.props.location} />}
                        <main id="page-main" css={betaStyle ? betaCss : newPageStyle ? newMainCss : readerSiteNav ? pageMainCss : publicCss}>
                            {da?.users?.length > 0 && da?.page === this.props.location.pathname && <Alert icon={<i className="fa fa-exclamation-triangle" />} text={<div>
                                {da?.users.map(user => <div key={user.id}>{user.name} {user.clientName ? <span>({user.clientName})</span> : ''} is {user.isEditing ? <strong>editing</strong> : 'viewing'} this page</div>)}
                            </div>
                            } />}
                            <Routes auth={this.props.auth} />
                        </main>
                    </div>
                </ErrorBoundary>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    searchParams: state.searchParams,
    dashboardActivity: state.dashboardActivity
});

const mapDispatchToProps = (dispatch) => ({
    flexActions: bindActionCreators(flexAuthAC, dispatch),
    vtActions: bindActionCreators(verifyTokenAC, dispatch),
    spActions: bindActionCreators(searchParamsAC, dispatch),
    daActions: bindActionCreators(dashboardActivityAC, dispatch),
    loginActions: bindActionCreators(loginAC, dispatch)
});

export const withExpandedMenu = (Component) => {
    return (props) => {
      const [expandedMenuSections, setExpandedMenuSections] = useExpandedMenu();
  
      return <Component expandedMenuSections={expandedMenuSections} setExpandedMenuSections={setExpandedMenuSections} {...props} />;
    };
};

export default withRouter(withExpandedMenu(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App))
);
