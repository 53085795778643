import {
    IS_EDITING_FORM,
    IS_EDIT_PAGE,
    UPDATE_DA_FAILURE,
    UPDATE_DA_SUCCESS,
    UPDATE_DA_REQUEST
} from '../constants/DashboardActivity';

import config from 'app/config';

export function isEditingForm(editFormPath) {
    return {
        type: IS_EDITING_FORM,
        payload: {
            editFormPath: editFormPath
        }
    }
}

export function isEditPage(resourceName, resourceId, editPagePath) {
    return {
        type: IS_EDIT_PAGE,
        payload: {
            resourceName: resourceName,
            resourceId: resourceId,
            editPagePath: editPagePath
        }
    }
}

export function updateDashboardActivitySuccess(data) {
    return {
        type: UPDATE_DA_SUCCESS,
        payload: data
    }
}

export function updateDashboardActivityFailure(error) {
    return {
        type: UPDATE_DA_FAILURE,
        payload: error
    }
}

export function updateDashboardActivityRequest() {
    return {
        type: UPDATE_DA_REQUEST
    }
}

export function updateDashboardActivity(auth, page, queryParams, isEditingForm, isEditPage, resourceName, resourceId, isUnauthorized) {
    return function (dispatch) {
        dispatch(updateDashboardActivityRequest());

        fetch(`${config.baseURI}/nv/dashboard-activity`, {
            method: 'POST',
            body: JSON.stringify({
                page: page,
                queryParams: queryParams,
                isEditing: isEditingForm,
                isEditPage: isEditPage,
                resourceName: resourceName,
                resourceId: resourceId
            }),
            headers: {
                'Authorization': 'Bearer ' + auth.token,
                'Content-Type': 'application/json'
            }
        }).then(response => {

            if (response.status === 401) {
                isUnauthorized();
            }

            if (response.status === 200) {
                return response.json()
            }
        }).then(data => {

            if (data) {
                dispatch(updateDashboardActivitySuccess(data));
            } else {
                dispatch(updateDashboardActivityFailure(data));
            }

        }).catch(error => {});

        return;
    }
}


// .then(
//     response => dispatch(response.errors ? actionCreators.getFailure(response.errors) : actionCreators.getSuccess(response.data[0])),
//     exception => dispatch(actionCreators.getFailure(exception))
// )