import * as React from 'react';

/* @jsxImportSource @emotion/react */  import { css, jsx } from '@emotion/react'; /* eslint-disable-line */

const componentCss = css`

    background-color: #F3F6F9;
    border-color: #F3F6F9;
    display: flex;
    align-items: stretch;
    padding: 1.5rem 2rem;
    position: relative;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.42rem;

    .alert-icon {
        display: flex;
        align-items: center;
        padding: 0 1.25rem 0 0;
        color: #3F4254;
    }

    .alert-text {
        align-self: center;
    }

    p:last-child {
        margin-bottom: 0;
    }

`;

export default React.forwardRef(({ className, icon, text, children }, ref) => {
    return <div className={className} css={componentCss}>
        <div className="alert-icon">
            {icon}
        </div>
        <div className="alert-text">
            {text ?? children}
        </div>
    </div >
});